import en from "./_en";
import es from "./_es";
import pl from "./_pl";
import pt from "./_pt";
import ru from "./_ru";
import sv from "./_sv";
import zh from "./_zh";

export default {
  en,
  es,
  pl,
  pt,
  ru,
  sv,
  zh,
};
