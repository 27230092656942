export default {
  menus: {
    about_us: "关于我们",
    mission_methodology: "我们的使命和方法",
    our_program: "我们的课程",
    meet_our_team: "认识我们的团队",
    frequently_asked_questions: "常见问题",
    work_with_us: "成为我们的合作伙伴",
    campus: "校园",
    barcelona: "巴塞罗那",
    madrid: "马德里",
    courses: "课程",
    spanish_course: "西班牙语课程",
    english_course: "英语课程",
    placement_test: "级别测验",
    exams_certification: "级别证书",
    prices: "价格和目录",
    student_life: "学生生活",
    student_visa: "学生签证",
    accommodation: "住宿",
    medical_insurance: "医疗保险",
    extra_activities: "额外活动",
    contact_us: "联系我们",
    pathway: "途径",
    packages: "套餐",
    blog: "博客",
  },
  footer: {
    follow_us: "关注我们",
    follow_us_subtitle: "我们在这里玩得很开心！",
    keep_in_touch: "让我们保持联系吧！",
    keep_in_touch_subtitle:
      "如果您不想错过我们的任何新闻、独家优惠、活动和特别活动，请订阅我们的时事通讯！",
    subscribe: "订阅",
    subscribe_link: "http://eepurl.com/hSd_25",
    legal_disclaimer: "法律免责声明",
  },
  contact_form: {
    title: "投资在优质机构的学习",
    subtitle:
      "联系我们，开始计划在西班牙的教育。开始成为 #NLCollegeCommunity 的一员",
    full_name: "全名",
    accredited: "中心获得认可",
    accredited2: "塞万提斯认证",
    email: "E-mail",
    phone: "电话号码/Whatsapp",
    country: "国家",
    nationality: "国籍",
    campus: "首选校区",
    message: "留言",
    terms:
      "我已阅读并同意 \n <a href='/files/Política de Privacidad - NL College.pdf' download target='_blank'>NL College 隐私政策</a>，\n <a href='/files/ T&C NL COLLEGE.pdf' download target='_blank'>条款和条件、取消政策</a>，以及 \n <a href='/files/POLÍTICA DE COOKIES - NL College.pdf' download target='_blank' >Cookie 政策</a>。\n    ",
    attach_cv: "附上你的简历",
    tooBigError: "文件不能超过 2MB",
    button: "发送",
    contact_form: "请接受条款和条件",
    success_msg: "谢谢，您的留言已发送！",
  },
  price_calculator: {
    title: "计算价格",
    lang: "语言",
    es: "西班牙语",
    en: "英语",
    courses: "课程",
    acceleratedmorning: "加速上午班",
    acceleratedafternoon: "加速下午班",
    semi: "半密集型",
    extensive: "晚间课程",
    weeks: "周",
    weeks_placeholder: "1 到 42",
    price: "价格",
  },
  home: {
    header: {
      title: '生活、学习和<span class="text-secondary">沟通！</span>',
      subtitle:
        "在NL College学习，学习一门新语言，同时在巴塞罗那和马德里享受精彩的留学经历",
    },
    learning: {
      header: "学习",
      subheader: "一种新语言是一回事，",
      paragraph1: "但是，以完全不同的创新方式做到这一点完全是另一回事。",
      paragraph2:
        "我们的校园地处优越的地理位置，其现代化的设施和多元文化的教室不仅将丰富您的学习之旅，还会将其转变为一次更有趣、更刺激的冒险之旅。",
    },
    accreditation: {
      title: "认证",
      subtitle:
        "NL College马德里和巴塞罗那校区均已获得塞万提斯学院的认证，这意味着它符合塞万提斯学院中心认证系统设定的条件，该系统是唯一专门针对西班牙语作为外语教学的国际认证。",
      dele_siele: "NL College现在是DELE和SIELE的官方考试中心。",
      cambridge: "NL College是经授权的剑桥英语预备中心。",
    },
    tabs: [
      {
        image: "home-tabs-1",
        title: "课程",
        subtitle: '查找<span class="text-secondary">最适合您需求的课程</span>',
        links: [
          {
            title: "西班牙语",
            to: { name: "our_courses", hash: "#spanish" },
          },
          {
            title: "英语",
            to: { name: "our_courses", hash: "#english" },
          },
          {
            title: "考前预备课程",
            to: { name: "our_courses", hash: "#exam_preparation" },
          },
        ],
      },
      {
        image: "home-tabs-2",
        title: "校园",
        subtitle: '探索<span class="text-secondary">最好的校园</span>',
        links: [
          {
            title: "巴塞罗那",
            to: { name: "our_campus", hash: "#barcelona" },
          },
          {
            title: "马德里",
            to: { name: "our_campus", hash: "#madrid" },
          },
        ],
      },
      // {
      //   image: "home-tabs-3",
      //   title: "路径",
      //   subtitle: '探索您的<span class="text-secondary">成功之路</span>',
      //   links: [
      //     {
      //       title: "了解更多",
      //       to: { name: "pathway" },
      //     },
      //   ],
      // },
      // {
      //   image: "home-tabs-4",
      //   title: "套餐",
      //   subtitle: '提升您的语言学习<span class="text-secondary">体验</span>',
      //   links: [
      //     {
      //       title: "选择您的套餐",
      //       to: { name: "our_packages" },
      //     },
      //   ],
      // },
      {
        image: "home-tabs-5",
        title: "课外活动",
        subtitle: '完整体验 <span class="text-secondary">交换生活</span>',
        links: [
          {
            title: "选择您的套餐",
            to: { name: "extra_activities" },
          },
        ],
      },
    ],
    enjoy: {
      header: "请享用",
      subheader: '你的<span class="text-secondary">新生活</span>',
      paragraph:
        "参加我们的课外活动，与其他国家的学生见面，充分享受西班牙的乐趣，体验完整的交流体验。",
      cta: "点击了解更多",
    },
    discover_campuses: {
      header: '探索我们的<span class="text-secondary">校园</span>',
      barcelona: "巴塞罗那",
      madrid: "马德里",
    },
    testimonials: {
      what_students_say: "我们的学生对我们的评价",
      paragraph: "文章",
    },
    explore: {
      header: "让我们来探索吧！",
      card1: {
        header: "多了解一下我们！",
        body: "让我们自我介绍一下吧！",
      },
      card2: {
        header: "自然学习与交际计划",
        body: "详细了解我们的独家计划",
      },
      card3: {
        header: "学生生活",
        body: "我们在整个过程中为您提供支持。在NL College，我们支持你！",
      },
    },
    contact_form: {
      location: "巴塞罗那海滩，巴塞罗那",
    },
    swiper_items: [
      {
        img: "home-sw-1",
        caption: "专业指导",
      },
      {
        img: "home-sw-2",
        caption: "多元化社区",
      },
      {
        img: "home-sw-3",
        caption: "现代化设施",
      },
      {
        img: "home-sw-4",
        caption: "独特的方法",
      },
      {
        img: "home-sw-5",
        caption: "多语言支持",
      },
      {
        img: "home-sw-6",
        caption: "签证支持",
      },
      {
        img: "home-sw-7",
        caption: "灵活的日程安排和地点",
      },
    ],
    testimonial_items: [
      {
        name: "Stefanie Heyse",
        date: "2023 年 3 月",
        body: "能在这所学校学习西班牙语我真的很高兴 \n 在巴塞罗那。我的顾问Carla非常好地告诉了我 \n 所有东西，能有她担任我的学生顾问真是太好了 \n ！课程结构清晰，老师 \n 适应课堂节奏并能很好地解释所有内容。 \n 我绝对推荐这所学校！",
      },
      {
        name: "Mariana F. Miguel",
        date: "2019 年 8 月",
        body: "我喜欢在NL College学习！学校位置很好，教职员工\n 从第一次接触起就非常周到，设施很新\n 干净，他们还会在课外组织活动，这样\n 学生们可以互相认识，也可以认识巴塞罗那。非常好！",
      },
      {
        name: "Jialu Tan",
        date: "2022 年 2 月",
        body: "我通常不会留下评论，但是我的老师帮了我很多，所以我\n 决定写这个评论。我没有西班牙语基础知识\n 在我来之前我完全都听不懂。我不能\n 一开始就明白课程内容，但是老师一直在帮我\n 用各种各样的图画，图片，甚至还有翻译器。我想\n 一开始和来自你自己国家的老师一起学习比较容易，但是\n 我选择了NL College是为了向当地老师学习更地道的发音。",
      },
      {
        name: "Alex Krasilnikov",
        date: "2021 年 11 月",
        body: "很棒的一堂课。与国际学生在一起的经历真是太有趣了）。\n 我喜欢！",
      },
    ],
  },
  methodology: {
    header_title: "我们的使命和方法",
    future: {
      choose_future: '选择你自己的<span class="text-primary">未来</span>',
      by_learning: "通过学习一门新语言",
      mission_paragraph:
        "我们认为，沟通是人类可以开发的最重要的工具。我们的使命是让我们的学生能够自信地生活、学习和说西班牙语和英语，使他们能够探索世界并清晰地表达自己的观点，因为他们知道自己会被理解。",
      history_1:
        "在2017年，我们在马德里成立了我们西班牙的第一个校区。我们的主要目标是帮助我们的学生学习一门新语言，同时创造难忘的经历和回忆。",
      history_2:
        "2022 年，我们决定是时候扩大和发展了：为了实现这一目标，一个拥有顶级设施的新校区在巴塞罗那落成。我们对学生的承诺始终如一--提供优质教学，确保他们永远珍惜在西班牙的时光。我们拥有一支经验丰富、母语为西班牙语的教师团队和多元文化支持人员，他们随时为您答疑解惑，为您的幸福生活带来力量。",
      joining_nlcollege:
        "加入 #NLCollegeCommunity 意味着为您的个人和职业成长打开大门，结识来自世界各地的人们，并在马德里或巴塞罗那度过一段神奇的时光。我们在这里帮助您的旅程，我们迫不及待地想让您加入我们的大家庭。",
    },
    methodology_section: {
      title: "方法论",
      description:
        "我们在NL学院的重点是有效的沟通。我们的课程旨在让学生掌握必要的西班牙语和英语技能，包括语法、发音和词汇。",
      items: [
        {
          body: 'NL 学院提供欧洲共同参考框架定义的不同级别的西班牙语课程 (\n <a\n href="https://www.coe.int/en/web/common-european-framework-reference %20languages/level-descriptions"\n target="_blank"\n >CEFR</a>\n ) 以及\n <a\n href="https://cvc.cervantes。 es/ensenanza/biblioteca_ele/plan_currillary/default.htm"\n target="_blank"\n >PCIC</a>\n（塞万提斯学院课程计划），制定和设置西班牙语参考\n级别。',
        },
        {
          body: "从上课的第一天起，你就会说西班牙语。NL学院的\n 教学方法基于当前的语言哲学\n 沉浸感：完全用西班牙语学习和教学。所有的教科书和\n 教材从一开始就使用西班牙语。",
        },
        {
          body: "NL学院有专为向外国人教西班牙语而设计的教科书。\n 这些教科书之所以被特别选中，是因为它们既现代又现代\n 完整的方法论。它们是 Edinumen 的 “Nuevo Prisma” 系列\n 出版社，范围从 A1 到 C2。这套书包括\n 教科书、工作簿以及 EleteCA 中的数字扩展，包括\n 扩展材料的内容和活动，例如练习，\n 补充讲义和视听材料。",
        },
        {
          body: "学院西班牙语课程的设计遵循交际,\n          行动导向, 以学生为中心的方法。这些课程的设计遵循\n          交际、行动导向、以学生为中心的方法，旨在让学生清晰易懂地进行语法思考，\n         并通过各种活动积极参与学习。",
        },
        {
          body: "NL学院为学生提供学习和沟通策略\n 反思他们的学习过程。通过跨文化反思和\n 发现西班牙世界的文化多样性。",
        },
        {
          body: "在NL学院，你将培养不同的语言技能，包括\n 理解、表达、互动或调解，依照\n CEFR。",
        },
        {
          body: "对于荷兰学院来说，教学质量至关重要。我们的\n 教学人员完全由高素质的西班牙语母语组成\n 具有向外国人教西班牙语经验的会说话的老师。我们所有的\n 荷兰学院的教师拥有西班牙语大学学位，\n SFL（西班牙语作为外语教学）的特殊培训。",
        },
      ],
    },
    levels: {
      title: "关于我们的课程分级",
      description:
        "我们提供全面的渐进式语言学习计划，适合所有级别的个人。无论你是绝对的初学者还是高级学生，我们的课程都旨在帮助你从 A1 级初学者一直升到精通级别 C2。当你完成每个级别时，你将获得一份文凭。",
      items: [
        {
          theme: "secondary",
          title: "A1：入门",
          courses: ["从 A1.1 到 A1.2", "官方 DELE A1 文凭"],
          body: "您将能够非常流利、准确地理解、总结和表达自己，并且可以轻松理解您阅读或听到的所有内容",
        },
        {
          theme: "secondary",
          title: "A2：基础",
          courses: ["从 A2.1 到 A2。", "官方 DELE A2 文凭"],
          body: "您将能够理解主要兴趣领域中最常见的表达方式和句子（例如个人信息、描述家庭和当地地理）",
        },
        {
          theme: "tertiary",
          title: "B1：进阶",
          courses: ["从 B1.1 到 B1.4", "官方 DELE B1 文凭"],
          body: "您将能够自己旅行而不会出现语言问题。你还将能够描述事件和梦想，并提出合理的解释、意见和计划",
        },
        {
          theme: "tertiary",
          title: "B2：高阶",
          courses: ["从 B2.1 到 B2.5", "官方 DELE B2 文凭"],
          body: "你将能够更自信地与母语人士互动，流利程度和自发性，并且能够轻松编写不同主题的详细文本",
        },
        {
          theme: "primary",
          title: "C1：流利",
          courses: ["从 C1.1 到 C1.6", "官方 DELE C1 文凭"],
          body: "您将能够理解较长的文本、复杂的对话，并且可以在不使用明显的文字/表情的情况下表达自己的观点",
        },
        {
          theme: "primary",
          title: "C2：精通",
          courses: ["从 C2.1 到 C1.7", "官方 DELE C2 文凭"],
          body: "你将能够非常流利、准确地理解、总结和表达自己，并且可以轻松理解你所读或听到的一切",
        },
      ],
    },
    progression: {
      title: '了解你的<span class="text-primary">进程</span>。',
      subtitle:
        "通过跟踪您的进度，您可以更好地了解我们的课程结构如何帮助您实现学习目标。",
      spanish: "西班牙语课程",
      english: "英语课程",
      accelerated: "加速课程",
      semi_intensive: "半加速课程",
      extensive: "晚间课程",
      weeks: "周",
    },
    letushelp: {
      title: "让我们来帮助你",
      card1: {
        header: "不确定自己处于什么级别？",
        body: "我们准备了一份测试来确定您的英语和西班牙语水平水平",
      },
      card2: {
        header: "哪门课程最适合你的需求？",
        body: "我们为每个人提供选择",
      },
      card3: {
        header: "谁说学习不可能很有趣？",
        body: "我们每个月都有不同的额外文化活动。看看他们！",
      },
    },
    contact_form: {
      location: "马德里雷蒂罗公园鸟瞰图",
    },
  },
  our_program: {
    page_header: "我们的节目",
    section1: {
      heading: '了解我们的<span class="text-primary">计划</span>',
      subheading: "自然学习与交际计划",
      text1:
        "在NL学院，我们相信以不同的方式做事！我们的计划是独一无二的，基于更自然的方法。与传统方法不同，我们采用更具沉浸感的方法，遵循学习母语的方式。我们致力于为学生提供最佳的学习体验，我们很高兴向您展示我们的与众不同之处！",
      text2:
        "在NL语言学院，我们希望让西班牙语学习从一开始就是一个自然的过程。我们的课程鼓励学生从第一天起就用西班牙语思考，而不是从母语翻译过来。我们相信快速吸收、敏捷性和流畅性，帮助学生获得在课堂内外交流的信心。通过我们独有的课程，学生可以在不同的课程和级别中取得进步，从而实现个人的学习目标。我们鼓励在实践中学习，我们的教学方法以学生为中心。在NL语言学院，我们相信以行动为基础的学习，激励学生充分发展自己的语言技能。",
      text3:
        "通过我们独有的课程，学生可以在不同的课程和级别中取得进步，从而实现个人学习目标。我们鼓励在实践中学习，我们的教学方法以学生为中心。在NL语言学院，我们相信以行动为基础的学习，激励学生充分发展自己的语言技能。",
    },
    section2: {
      title: '专属于您<span class="text-primary">量身定制的计划！</span>',
      description:
        "在NL学院，教学和学习以课程进展为基础，随着你从一个课程到另一个级别的进展，难度和内容量以及时长会增加，可能会在36周内达到高级水平。",
      description2:
        "想知道更多？没有其他学校能像NL学院那样提供如此详尽和透明的课程。您将清楚而简单地知道每个课程和级别的持续时间。我们将准确地告知您我们每门课程的语法、词汇和交际内容。想知道你什么时候能学习假设语气吗？在NL学院，我们会告诉你一切。",
      heading: "让我们<span class='text-primary'>开始吧！</span>",
      items: [
        {
          titleClass: "text-secondary",
          title: "入门和基础",
          subtitle: "A1.1 | A1.2 | A2.1 | A2.2 | A2.3",
          body: '\n          <p class="h6">A1.1</p>\n <p>欢迎来到荷兰学院！</p>\n <p>\n 你叫什么名字？你来自哪里？在本课程中，你将有\n 你第一次接触西班牙语从最基础开始，学会\n 拼写您的姓名并提供您的手机号码或电子邮件地址。\n 你将能够在学习的同时提供和询问个人信息\n 共轭正则现在时动词。很高兴见到你！</p>\n <p class="h6">A1.2</p>\n <p>\n 你有兴趣进一步了解西班牙文化吗？在这个\n 当然，你要用现在时练习所有不规则动词\n 解释你的日常生活。你还将学会谈论点赞，\n 兴趣、欲望和偏好（甚至为未来制定计划）\n 然后在格拉西亚或丘埃卡的典型酒吧点小吃\n 社区。尽情享受！</p>\n <p class="h6">A2.1</p>\n <p>\n 现在你知道了现在时（以及不久的将来），我们想要\n 多了解一下你：你的身体长什么样，你的\n 房子是什么样子，或者你喜欢哪种食物。我们开始关卡了\n A2 通过扩大我们的词汇量和练习我们所掌握的一切\n 学到了。</p>\n <p class="h6">A2.2</p>\n <p>\n 下一步是学会谈论你过去的经历并讲述\n 我们稍微介绍一下你的简历。我们还将教你如何保持礼貌\n 要求并给出和理解小指示。生活，学习，\n 并与荷兰学院交谈！</p>\n <p class="h6">A2.3</p>\n <p>\n 你知道西班牙语有 4 种官方语言吗？那你有没有\n 知道在 A2.3 课程中，你将学会谈论习惯性的过去\n 行动和分享趣闻？我们还将为以下方面组织最佳计划\n 通过练习将来时态来获得空闲时间。感谢我们的独家报道\n 程序，你已经完成了第 A 阶段，现在是的基础用户\n 西班牙语。</p>\n          ',
        },
        {
          titleClass: "text-tertiary",
          title: "进阶和高阶",
          subtitle: "B1.1（初学者和高级）| B1.2（初学者和高级）| B2.1 | B2.2",
          body: '\n <p class="h6">B1.1 初始</p>\n <p>\n 恭喜！ 过去、现在和未来：您已经完成了一半，从西班牙语学习旅程 B 阶段的初始水平开始。 让我们通过谈论工作或我们的习俗开始练习更复杂的结构。 我们可以开始了吗？\n </p>\n <p class="h6">B1.1 高级</p>\n <p>\n 您能够基本说、写和理解 水平，现在是时候进一步发展您的口头和书面技能了。 是时候练习、扩大词汇量、学习如何提供建议、表达条件或理解笑话了。 明白了吗？\n </p>\n <p class="h6">B1.2 初级</p>\n <p>\n 我们祝您在 NL 学院获得美好的学习体验！ 所以现在，\n我们将向您介绍每个西班牙语学习者最喜欢的虚拟语气，\n所有西班牙人在谈论他人时每天都会使用它来表达感情，\n寻找理想的工作，或表达\n原因 以及您学习西班牙语的目的。\n </p>\n <p class="h6">B1.2 高级</p>\n <p>\n 您即将完成 B1 级，并且 您已经可以进行中等水平的沟通和理解。 您对西班牙和其他西班牙语国家的了解越来越多，并且您学会了表达意见、表达愿望或重复别人的话。 您已经了解\n生态学、社会活动主义或神秘事物。 现在会发生什么？\n </p>\n <p class="h6">B2.1</p>\n <p>\n 太不可思议了，你竟然能参加高级课程 !\n 干得好！ 在本课程中，您将把迄今为止学到的所有语法付诸实践，\n 您将能够特别提高\n 口语表达能力，并将词汇量扩展至更高级的水平。\n </p >\n <p class="h6">B2.2</p>\n <p>\n 你越来越接近母语水平，\n 你已经可以用西班牙语与 充满信心且流利。\n 在本课程中，您将为进入 C1 做好准备，从而成为\n 熟练的西班牙语使用者。 您的进步令人印象深刻，\n您已经非常接近实现目标。 如果您想达到精通水平，请咨询 NL 学院的 C1 和 C2 级别课程。 我们走吧！\n </p>\n',
        },
        {
          titleClass: "text-primary",
          title: "流利和精通",
          subtitle: "C1.1 | C1.2 | C2.1 | C2.2",
          body: '\n <p class="h6">C1.1</p>\n <p>\n 作为一名合格学生，您已达到阶段 C。 在本课程中，您将能够理解各种广泛的文本并撰写清晰且结构良好的文本。 您将能够以更流畅、更自然的方式表达自己。\n </p>\n <p class="h6">C1.2</p>\n <p>\n C1 级对应于该语言的熟练用户，能够执行复杂的工作和学习任务。 因为在 NL 学院，我们支持您\n 实现目标，而 C1.2 课程可帮助您为\n C2 级做好准备。\n </p>\n <p class="h6">C2 .1</p>\n <p>\n 西班牙语 C2 级基本上是母语水平。 它将使您能够参与任何主题的任何类型的写作和阅读。 您将能够\n轻松理解您听到或读到的所有内容，并\n非常流畅和精确地表达自己。\n </p>\n <p class="h6">C2.2</p >\n <p>\n 你在 NL 学院的旅程即将结束。 有您与我们一起并陪伴您踏上这段伟大的冒险之旅真的很值得。 您已达到精通级别，成功完成\n您的学术课程。\n </p>\n',
        },
      ],
    },
    section3: {
      title: "我们才刚开始！",
      card1: {
        heading: "不确定自己处于什么级别？",
        text: "我们准备了一份测试来确定您的英语和西班牙语水平水平",
      },
      card2: {
        heading: "哪门课程最适合你的需求？",
        text: "我们为每个人提供选择",
      },
      card3: {
        heading: "谁说学习不可能很有趣？",
        text: "我们每个月都有不同的额外文化活动。看看他们！",
      },
    },
    contact_form: {
      location: "高迪的巴特罗故居，巴塞罗那",
    },
    level_items: [
      {
        0: {
          color: "中等教育",
          name: "A1.1",
          body: '\n <h6>欢迎来到 NL 学院！</h6>\n <p class="fw-300">\n <i>你叫什么名字？ 你从哪来？ 您住在\n NL大学校园附近吗？</i>\n </p>\n <p class="fw-300">\n 在本级别中，您将学习给予和索取个人\n 在商店购物或向我们描述您的社区时，\n 定期了解信息并结合当前情况。\n </p>',
        },
        1: {
          color: "中等教育",
          name: "A1.2",
          body: '<p class="fw-300">\n 您有兴趣了解更多西班牙文化吗？ 在本课程中，除了解释您的日常生活之外，您还将练习当前的所有不规则动作。 您还将学习谈论品味、兴趣、愿望和偏好，并为未来制定计划。此外，您还将学习如何在 Gracia 或 Chueca 社区的典型酒吧点小吃：开动吧 !\n</p>',
        },
      },
      {
        0: {
          color: "中等教育",
          name: "A2.1",
          body: '<p class="fw-300">\n 现在您知道了现在和不久的将来，您将学习\n 谈论您过去的经历或谈论历史事件并\n 告诉我们 关于你的简历的一些信息。 我们通过学习过去时态和谈论未来事件来开始 A2 级别。\n </p>',
        },
        1: {
          color: "中等教育",
          name: "A2.2",
          body: '<p class="fw-300">\n 您知道西班牙有 4 种官方语言吗？ 您是否知道在 A2.2 级别课程中，您将学习谈论过去的习惯性行为并讲述轶事？ 如果你想不断进步，我们建议你努力学习，来上课，在NL学院不要担心任何事情。 感谢我们的独家计划，您已经完成了 A 阶段，并且您已经是西班牙语的基本使用者。\n </p>',
        },
      },
      {
        0: {
          color: "第三",
          name: "B1.1",
          body: '<p class="fw-300">\n 恭喜！！ 您已经完成了一半，您可以很自然地开始西班牙语学习的 B 阶段。 您可以进行基本的说、写和理解，现在是培养口语和书面能力的时候了。 是时候练习、扩大词汇量并学习\n 提供建议、做出预测和猜测或谈论假设的\n 情况。\n </p>',
        },
        1: {
          color: "第三",
          name: "B1.2",
          body: '<p class="fw-300">\n 我们祝您在 NL 学院拥有美好的学习体验！ 所以现在\n我们将向您介绍每个人最喜欢的西班牙语话题，我们心爱的\n虚拟语气，所有西班牙人每天都用它来表达\n在谈论他人时的感受，\n找到你理想的工作，或表达\n的原因和目的\n 您的西班牙语学习情况。\n </p>',
        },
        2: {
          color: "第三",
          name: "B1.3",
          body: '<p class="fw-300">\n 您即将完成 B1 级，\n 已经可以进行中等水平的沟通和理解。 您对西班牙和拉丁美洲的了解越来越多，并学会了表达意见、道歉、谈论好恶、写动机信或创作漫画。\n </p>',
        },
      },
      {
        0: {
          color: "第三",
          name: "B2.1",
          body: '<p class="fw-300">\n 难以置信！ 您可以参加高级课程了！ 做得好！ 在本课程中，您将练习迄今为止学到的所有语法，并能够提高您的口语表达能力并将词汇量增加到更高级的水平。\n </p>',
        },
        1: {
          color: "第三",
          name: "B2.2",
          body: '<p class="fw-300">\n 您越来越接近母语水平，现在可以充满信心地用\n 西班牙语进行交流。 在本课程中，您将准备进入 C1 并成为一名合格的西班牙语使用者。 您的进步令人印象深刻，并且您非常接近实现目标。 如果您想\n 达到精通水平，请询问 NL\n 学院的 C1 和 C2 级别课程。 我们走吧！\n </p>',
        },
      },
    ],
  },
  meet_our_team: {
    page_header: "认识我们的团队",
    section1: {
      heading: '很高兴<span class="text-primary">认识你！</span>',
      title: "欢迎来到荷兰大学社区！",
      body: "我们的团队由母语教师和多元文化团队组成，致力于为学生提供最好的支持。 无论您在抵达西班牙之前还是之后需要帮助，我们都会随时为您提供帮助。 与我们一起建立一个致力于卓越语言学习的强大社区。",
    },
    meet_our_teachers: "认识我们的老师",
    barcelona: {
      title: "巴塞罗那校区",
      team: [
        {
          image: "brc-team-clara",
          first_name: "克拉拉",
          last_name: "戈麦斯·卢塞纳",
          education: ["视听通信许可证", "CLIC 教授证书"],
        },
        {
          image: "brc-team-vanessa",
          first_name: "凡妮莎",
          last_name: "卡萨诺瓦",
          education: ["UB 西班牙语语言学", "UB ELE 官方教授硕士"],
        },
        {
          image: "brc-team-jone",
          first_name: "琼斯",
          last_name: "加西亚·拉拉尼亚加",
          education: ["信息科学学士学位", "ELE、IH 教授培训"],
        },
        {
          image: "brc-team-ana-maria",
          first_name: "安娜玛丽亚",
          last_name: "马丁内斯·费雷拉",
          education: ["文学学士", "ELE 应用语言学硕士"],
        },
        {
          image: "brc-team-mireia",
          first_name: "米雷亚",
          last_name: "马丁·佛朗哥",
          education: ["不列颠哥伦比亚大学西班牙语系学位", "英国电气硕士"],
        },
      ],
    },
    madrid: {
      title: "马德里校区",
      team: [
        {
          image: "mdr-team-desiderio",
          first_name: "德西德里奥",
          last_name: "桑托尼亚·马丁内斯",
          education: ["西班牙语和文学等级", "DELE 考官 A1-A2/B1-B2/C1-C2"],
        },
        {
          image: "mdr-team-david",
          first_name: "大卫",
          last_name: "加泰罗尼亚海林",
          education: ["西班牙语等级：语言与文学", "第二语言为西班牙语硕士"],
        },
        {
          image: "mdr-team-isabel",
          first_name: "伊莎贝尔",
          last_name: "卢卡斯·纳瓦隆",
          education: ["法国语言学和 CAP 学位", "DELE C1/C2 考试课程"],
        },
        {
          image: "mdr-team-fatima",
          first_name: "法蒂玛",
          last_name: "赫兰兹·迪亚兹",
          education: ["西班牙语教学硕士"],
        },
        {
          image: "mdr-team-ruben",
          first_name: "鲁本",
          last_name: "格拉·科拉尔",
          education: ["语言学和 ELE 硕士", "附有特定细节的西班牙语课程"],
        },
      ],
    },
    section_help: {
      header: "进一步了解我们！",
      card1: {
        title: "你想知道更多关于巴塞罗那的信息吗？",
        body: "探索我们的巴塞罗那校区",
      },
      card2: {
        title: "你想知道更多关于马德里的信息吗？",
        body: "探索我们的马德里校区",
      },
      card3: {
        title: "哪门课程最适合你的需求？",
        body: "你是赶时间还是计划长期居留？我们为每个人提供选择",
      },
    },
    contact_form: {
      location: "厄尔奥索和埃尔马德罗尼奥雕塑，太阳广场，马德里",
    },
  },
  faqs: {
    header: "经常问的问题",
    section1: {
      heading: '有任何<span class="text-primary">问题吗？</span>',
      paragraph1:
        "我们知道踏上新的旅程可能会让人不知所措，但别担心，我们随时为您提供帮助。我们的团队拥有丰富的知识和经验，可以指导像您这样的学生解决您可能遇到的任何问题或疑问。",
      paragraph2:
        "我们很乐意为您提供所需的答案。 我们的目标是确保您在 NL College 的学习尽可能顺利、无压力。",
      paragraph3:
        "如果您不确定从哪里开始，我们整理了一份常见问题清单，这些问题可能会对您有所帮助。但是，如果您需要进一步的澄清或有其他问题，请随时与我们联系。我们在这里确保您在我们这里获得成功而愉快的体验。",
    },
    contact_form: {
      location: "哥特区，巴塞罗那",
    },
    faq_sections: [
      {
        title: "课程",
        items: [
          {
            subtitle: "提供哪些课程？",
            body: '\n              <p>\n                我们提供各种语言课程以满足您的需求, 包括西班牙语\n                和英语。\n              </p>\n\n              <p>\n                我们的西班牙语课程是：\n              </p>\n\n              <ul  class="disc-style">\n                <li>\n                  每周 20 小时加速（唯一满足\n                  长期签证要求的项目）\n                </li>\n                <li>\n                 半强化课程，每周 10 小时。\n                </li>\n                <li>\n                  强化课程，每周周一和周三或\n                  周二和周四 4 小时。\n                </li>\n                <li>\n                  DELE 准备课程\n                </li>\n                <li>\n                  私人课程\n                </li>\n              </ul>\n\n              <p>\n                对于我们的英语课程，我们有：\n              </p>\n\n              <ul>      \n                <li>\n                  半强化课程，每周 10 小时。\n                </li>\n                <li>\n                  强化课程 每周 4 小时，周一和周三或\n                  周二和周四。\n                </li>\n                <li>\n                  剑桥预备课程\n                </li>\n                <li>\n                  私人课程\n                </li>\n              </ul>\n\n              <p>\n                我们知道您的时间很宝贵，因此我们提供一系列\n                课程选项来适应您的日程安排。 请随时与我们联系，了解\n                有关我们课程和时间表的更多信息。\n              </p>',
          },
          {
            subtitle: "我什么时候可以开始学习？",
            body: "\n              <p>\n 你可以每周一参加我们的课程。为确保你进入与你的技能水平相匹配的课程，我们要求所有新生完成分班考试。完成测试后，您可以选择最适合自己日程安排的课程并立即开始学习。</p>",
          },
          {
            subtitle: "我怎么知道自己的等级？",
            body: "<p>如果您已经与我们的代理商进行了交谈，他们将为您提供我们的分班考试的链接。如果您尚未与代理商交谈，请不要担心。您可以在我们的网站上轻松找到该测试。</p>\n <p>我们的分班考试旨在准确评估您的语言技能，并使您进入与您的熟练程度相匹配的课程。这样可以确保你从语言学习经历中获得最大收益，并能以适合自己的速度进步。</p>\n <p>因此，如果您准备好了解自己的语言水平并开始在荷兰学院学习，请立即参加我们的分班考试。</p>",
          },
          {
            subtitle: "有多少级别？",
            body: "<p>我们提供从 A1 到 C2 的所有熟练程度级别的语言课程。无论你是想要从头开始的新手还是希望提高技能的高级学习者，我们都有适合你的课程</p>。\n <p>加入我们充满激情的学习者社区，立即在荷兰学院开始您的语言学习之旅。</p>",
          },
          {
            subtitle: "一个级别需要多长时间才能完成？",
            body: "\n              <p>\n                我们了解每个学生都有自己独特的学习进度和目标。\n                因此，完成一个级别的语言课程所需的时间可能\n                根据您选择的具体课程而有所不同\n              </p>\n\n              <table>\n                <tr>\n                  <th></th>\n                  <th>A1</th>\n                  <th>A2</th>\n                  <th>B1</th>\n                  <th>B2</th>\n                  <th>C1</th>\n                  <th>C2</th>\n                </tr>\n                <tr>\n                  <th>加速</th>\n                  <td>4周</td>\n                  <td>4周</td>\n                  <td>6周</td>\n                  <td>24周</td>\n                  <td>26周</td>\n                  <td>26周</td>\n                </tr>\n                <tr>\n                  <th>半加速</th>\n                  <td>8周</td>\n                  <td>8周</td>\n                  <td>12周</td>\n                  <td>48周</td>\n                  <td>52周</td>\n                  <td>52周</td>\n                </tr>\n                <tr>\n                  <th>广泛</th>\n                  <td>20周</td>\n                  <td>20周</td>\n                  <td>30周</td>\n                  <td>120周</td>\n                  <td>130周</td>\n                  <td>130周</td>\n                </tr>\n              </table>\n\n              <p>\n                因此，如果您已准备好开始语言学习之旅，请立即联系我们\n                了解有关我们课程的更多信息并找到适合您的课程。\n              </p>\n            ",
          },
          {
            subtitle: "付款后我必须开始课程多长时间？",
            body: "\n<p>我们知道生活可能是不可预测的，在开始语言课程时，你可能需要灵活性。这就是为什么我们为学生提供在付款后的12个月内开始课程的选项。</p>\n\n<p>因此，如果你正在考虑报名参加荷兰学院的语言课程，你可以放心地这样做，因为你知道自己将获得成功所需的灵活性和支持。立即联系我们，了解有关我们课程的更多信息，并开始规划您的语言学习之旅。</p>\n            ",
          },
          {
            subtitle: "你们提供哪些类型的长期套餐？",
            body: "\n<p>我们提供一系列语言课程来满足学生的需求，包括为那些想要进行更广泛学习的学生提供的长期套餐。</p>\n\n<p>我们的课程从一开始 一周，可持续长达一年，长期套餐通常被认为是那些涉及六个月以上学习的套餐。 这些套餐旨在为我们的学生提供他们所需的时间和支持，让他们完全沉浸在语言中并在流利程度方面取得重大进步。</p>\n\n<p>因此，如果您准备好致力于 这是一次长期的语言学习之旅，请立即联系我们，了解有关我们套餐的更多信息并找到适合您的套餐。</p>\n            ",
          },
          {
            subtitle: "书是必需的吗？",
            body: "\n<p>为了确保最佳的学习体验，必须拥有课程所需的书籍。请务必记住，当你进入新的关卡时，将需要新的材料。如果您的课程套餐中不包含书籍，请不要担心；您可以在我们的接待处轻松购买。我们的团队将很乐意协助您获取所需的材料，以便您可以专注于实现学术目标。</p>\n            ",
          },
          {
            subtitle: "是否提供试听课？",
            body: "\n<p>是的。 我们提供免费试用，因为我们知道选择正确的语言学校是一项重要的决定，而且您要确保自己对自己的选择感到舒适和自信。 这就是为什么我们为未来的学生提供参加试听课的机会。 </p>\n\n<p>试听课是免费的，持续两个小时，你将有机会体验我们的教学方法并了解课堂环境。 这将使您更好地了解在我们这里学习的感觉，并帮助您决定荷兰学院是否适合您。 </p>\n\n<p>要预订试听课，只需让您的顾问知道您感兴趣，他们就会为您注册。 我们相信，你会喜欢我们的课程，并看到和我们一起学习的价值，所以不要犹豫，抓住这个机会。 立即联系我们，了解更多信息，并根据我们的空缺情况预订试听课。</p>\n            ",
          },
          {
            subtitle: "每个班的学生总数是多少？",
            body: "<p>我们坚信为学生提供一个亲密而引人入胜的学习环境。这就是为什么我们将每个班级的学生人数限制为最多 10 人。这样可以确保你得到老师的个性化关注，可以积极参与课堂讨论，并有充足的机会练习你的语言技能</p>。\n\n<p>因此，如果你正在寻找一所优先考虑个性化关注的语言学校和一所具有支持氛围的学校，NL学院就是你的最佳选择。</p>",
          },
          {
            subtitle: "这些课程是否由来自不同国籍的学生组成？",
            body: "<p>我们为为学生提供真正的国际化环境而感到自豪。我们的课程通常由来自世界各地的学生组成。我们欢迎来自不同国家的学生，我们课程中最常见的一些国籍包括：瑞典、英国、德国、意大利、中国、巴西、南非、法国、日本、美国和加拿大等。</p>\n\n<p>我们多元化的学生群体增加了学习语言的丰富文化体验，我们相信，与来自不同背景和文化的人互动是成为一名精通语言的人的重要组成部分。</p>\n\n<p>因此，如果你正在寻找机会学习一门语言，同时让自己沉浸在真正的全球环境中，NL学院就是你的最佳选择。立即联系我们，了解更多信息，与我们一起开始您的语言学习之旅。</p>",
          },
          {
            subtitle: "课程的方法是什么？",
            body: "<p>在NL College，我们相信有效的沟通是语言学习的基石。 我们的课程旨在帮助学生使用从我们这里学到的语法、发音和词汇自信地进行交流。 我们遵循沟通、以行动为导向、以学生为中心的方法，强调积极参与和边做边学。</p>\n\n<p>我们的课程符合 CEFR（欧洲共同语言参考框架） 和 PCIC（塞万提斯学院课程计划），确保我们的学生接受最高质量的语言教育。 在整个课程中，我们经验丰富的教师通过引人入胜的活动提供清晰易懂的语法反思，鼓励学生积极参与自己的学习。</p>\n\n<p>我们相信，从一开始说话是语言的关键 获取，我们鼓励学生这样做。 让我们通过我们动态的、以学生为中心的方法帮助您实现您的语言目标！</p>",
          },
          {
            subtitle: "我可以续订课程吗？",
            body: "<p>是的，你可以。 要续订课程，只需联系您在加入 NL College 时最初与之交谈的顾问，然后继续在我们这里学习语言。 不要犹豫，立即联系我们，我们将很乐意回答您的任何问题，并指导您完成注册流程。</p>",
          },
          {
            subtitle: "我仍然对所提供的内容有疑问，我该怎么做？",
            body: "<p>我们了解您可能对我们的产品有一些问题或疑虑，我们随时为您提供帮助！ 请随时联系我们知识渊博的顾问，他们随时准备为您提供有关我们的课程以及在西班牙生活的感觉所需的所有信息。 </p>\n\n<p>我们致力于确保您拥有对教育做出明智决定所需的所有信息，因此请随时联系我们！</p>",
          },
        ],
      },
      {
        title: "住宿",
        items: [
          {
            subtitle: "NL学院提供住宿吗？",
            body: "<p>绝对地！ 我们知道搬到国外可能是一次令人畏惧的经历，因此我们通过合作伙伴提供一系列住宿选择，以使您尽可能顺利、轻松地过渡。</p>\n\n<p >我们的住宿合作伙伴网络确保您将被安置在学校附近、交通便利的安全舒适的地方。 当然，这项服务需要支付额外费用，但我们相信它所提供的安心和便利是非常值得的投资。</p>\n\n<p>让我们帮助您在 NL College 的时光，照顾好您的住宿需求！</p>",
          },
          {
            subtitle: "你们提供哪种类型的住宿？ ",
            body: "<p>我们通过合作伙伴提供各种住宿选择，以满足您的需求和偏好。您可以选择共享公寓、学生宿舍或宿舍，具体取决于您的预算和最适合您的方式。</p>\n\n<p>每个选项都旨在为您提供舒适便捷的生活空间，让您专注于学习，在我们充满活力的社区中享受时光。</p>\n\n<p>请告诉我们您更喜欢哪个选项，我们将尽一切努力确保您在我们这里有宾至如归的感觉！</p>",
          },
          {
            subtitle: "如果我的课程不包括住宿，我可以额外付费吗？",
            body: "<p>好消息！是的，您可以选择将住宿作为额外费用添加到课程套餐中。只需联系您在注册时最初与之沟通的代理商，他们将很乐意为您提供帮助。</p>",
          },
          {
            subtitle: "每月在巴塞罗那或马德里生活需要多少钱？",
            body: "<p>请记住，费用可能会因多种因素而异，例如您的住宿和生活方式。</p>\n\n<p>例如，在巴塞罗那，共用公寓中的私人房间可能会花费您约 350 欧元。 至 600 欧元 每月。 您需要额外预算 40 欧元； 每月交通卡和 50-100 欧元； </p>\n\n<p>在马德里，合租公寓中的私人房间的价格通常在 300 欧元至 500 欧元；每月。 交通卡费用约为每月20欧元，您需要分配50欧元-100欧元。 </p>\n\n<p>为了满足您的通信和娱乐需求，预付费电话套餐的费用通常为15 欧元。 28天，每月平均Fibra 600Mbps WIFI套餐费用约为33欧元。</p>\n\n<p>食品方面，西班牙超市消费以基本菜篮子为参考，一般情况下， 每月费用 101 欧元。 请记住，个人消费习惯可能会影响您的超市总账单。</p>\n\n<p>此信息基于 Numbeo 网站。 查看马德里的公共交通价格：[crtm.es](http://crtm.es/) 和巴塞罗那 tmb.cat</p>",
          },
        ],
      },
      {
        title: "付款",
        items: [
          {
            subtitle: "我该如何支付课程费用？",
            body: "<p>在我们学校，我们相信支付课程费用应该简单、无忧。 因此，我们为您提供三种便捷的付款方式。</p>\n\n<p>首先，您可以通过银行转帐支付课程费用，这是我们许多学生喜欢的一种安全可靠的方法。 此选项允许您将资金直接转入我们的学校帐户，让您放心，您的付款已收到并安全处理。</p>\n\n<p>或者，我们通过我们的在线支付工具提供在线支付工具。 值得信赖的合作伙伴飞汇。 通过这个平台，您可以使用一系列方便的付款方式快速轻松地进行付款。</p>\n\n<p>最后，对于那些喜欢更传统方式的人，我们也接受现金 或亲自在我们学校接待处刷卡付款，我们友好的工作人员将很乐意帮助您解决任何问题，并确保您的付款得到及时处理。 为了确保您的付款过程顺利进行，我们恳请您提前告知您的代理人您计划亲自付款。 这将使我们能够提前准备您的发票，并确保我们拥有快速准确地处理您的付款所需的所有信息。</p>\n\n<p>无论您首选哪种付款方式，我们都致力于 让您的注册尽可能顺利、无压力。 那为什么还要等呢？ 选择最适合您的选项，并朝着您今天的教育目标迈出第一步。</p>",
          },
          {
            subtitle: "我需要在课程开始之前付款吗？",
            body: "<p>在我们学校，我们要求您提前支付想要预订课程席位的周数。 这可确保我们确保您的注册并保证您在课堂上的座位。</p>\n\n<p>您的付款获得批准和处理后，您将能够放心地参加课程，因为您知道您的课程 名额已被预订，您的注册已被确认。</p>\n\n<p>因此，如果您准备好与我们一起开始您的教育之旅，我们鼓励您提前付款并在 课程。 我们的团队随时准备帮助您解决任何问题，并确保您的注册过程顺利无忧。</p>",
          },
          {
            subtitle: "你有促销价格吗？",
            body: "<p>在我们学校，我们认为每个人都应该有机会接受教育，无论他们的经济状况如何。这就是为什么我们致力于为您提供最实惠的定价选择和超值的投资价值的原因。</p>\n\n<p>因此，不要等待，向与您交谈的代理商询问我们今天的促销价格，然后迈出实现教育目标的第一步。</p>",
          },
        ],
      },
      {
        title: "签证",
        items: [
          {
            subtitle: "我需要学生签证才能在西班牙学习吗？",
            body: "<p>如果你不是欧盟公民，那么你需要学生签证才能在西班牙学习。要学习，您必须申请专门用于教育目的的居留许可。但是，如果您来自欧盟，则不需要学生签证，这不是必需</p>的",
          },
          {
            subtitle: "我需要什么类型的签证？",
            body: "<p>根据您的课程时间，我们提供三种不同的选择。</p>（n）<p>90 天以内的旅游签证，C 类</p>（n）<p>对于学习短期课程不超过 90 天的学生，旅游签证是最佳选择。来自欧盟/欧洲经济区和瑞士以外国家的留学生必须申请申根学生签证。但是，如果您来自不需要签证的国家，前往西班牙逗留不超过90天，则无需申请签证即可在西班牙学习。</p>n/n<p>180天D类封闭式学生签证</p>n/n<p>如果您计划学习3-6个月，我们的180天D类封闭式短期学生签证是您的最佳选择。该签证易于申请且不能延期。但是，如果您的学习计划超过 180 天，您将需要申请长期学生签证。</p>（n）<p>最长 180 天的 D 类开放式学生签证</p>（n）<p>对于那些希望学习超过 27 周课程的学生，我们的长期学生签证（D 类开放式）允许您获得居留卡（NIE），并将您在西班牙的学习时间延长至初始签证到期日之后。</p>\nn<p>不要让签证申请过程阻碍您实现在西班牙的教育梦想。现在就联系我们，了解更多项目选项！</p>",
          },
          {
            subtitle: "学校会帮助我获得学生签证吗？",
            body: "<p>我们始终在您身边！对于非欧盟公民来说，整个签证申请程序可能既困难又耗时。不过，您不必担心。NL学院和我们合作的律师事务所将为您提供帮助！我们在帮助非欧盟学生办理长期学生签证方面有着丰富的经验。</p>\n\n<p> 我们的多语种团队随时准备在您交换学生的这一重要步骤中为您提供指导。</p>/n\n<p>我们的长期项目包括您申请学生签证所需的学校官方文件和证书。我们将帮助您实现留学西班牙的梦想！现在就开始您的北欧语言学院留学生活吧！</p>n\n<p> 我们还与西班牙的一家律师事务所（律师）建立了合作关系，该律师事务所将为您的签证申请程序以及在西班牙定居的签证套餐提供额外费用的支持。</p>",
          },
          {
            subtitle: "如果我想要长期签证，我需要购买哪门课程？",
            body: "<p>要获得长期签证，您需要购买 6 个月以上的课程。而且，如果您计划在学习结束后延长在西班牙的逗留时间，您应该选择为期 27 周以上的课程。</p>\n\n<p>我们的代理团队可以帮助您以最优惠的价格找到最合适的课程。还等什么？现在就联系我们，开始您的西班牙留学之旅吧</p>",
          },
          {
            subtitle: "当我拿到学生签证后，下一步该怎么做？",
            body: "<p>一旦您的护照上有了 D 类长期签证并抵达西班牙，您就可以申请 NIE 卡了。</p>",
          },
          {
            subtitle: "NIE是什么？我怎样才能得到它？",
            body: "<p>您的 NIE（外国人身份证号码）是您在签证中已经拥有的身份证号码。这是一份重要的文件，可将您识别为居住在西班牙的外国人。这对于在该国开设银行账户、签订合同和开展许多其他重要活动至关重要。</p>\n\n<p>要获得 NIE 卡，您需要在最近的警察局或外国人办公室预约。在预约期间，您需要提供护照、签证和您在西班牙的地址证明。申请处理完毕后，您将收到您的 NIE 卡，该卡将在您在该国逗留期间有效。</p>",
          },
          {
            subtitle: "什么是TIE，我怎样才能得到它？",
            body: '<p>\n  TIE 是您的居留证实体卡，上面还包含您的 NIE。 要获得您的\n第一张 TIE 卡，您需要以下信息：\n</p>\n\n<ul class="disc-style">\n <li>护照原件和复印件</li> \n <li>签证页复印件</li>\n <li>学校录取通知书</li>\n <li>护照尺寸1张白底照片</li>\n <li>EX 17表格 </li>\n <li>家庭登记证明（Empadronamiento）</li>\n</ul>\n\n<p>\n 申请后，大约需要 30 至 45 天才能获得 准备好。\n</p>\n',
          },
          {
            subtitle: "我可以延长来自西班牙的学生签证吗？",
            body: "<p>好消息 如果您是正在西班牙学习的学生，您可以延长您的学生签证，而无需离开西班牙。这意味着您可以继续追求您的学业目标，享受西班牙提供的一切，不受任何干扰。</p>\n\n<p>要延长您的学生签证，只需在您当前逗留状态到期前 60 天内申请延期即可。您也可以在到期日后 90 天内申请延期，因此如果您错过了最初的截止日期，也不必惊慌。</p>/n\n<p> 学生签证延期后，您将可以在西班牙逗留更长的时间，让您有更多的时间融入当地文化、学习语言并实现您的学业目标。因此，不要让您的学生签证过期--现在就申请延期，继续您的西班牙探险之旅！ </p>",
          },
          {
            subtitle: "我以游客身份进入西班牙，我可以正规化我的签证吗？",
            body: "<p>你目前是否以游客的身份来西班牙，但想停留更长的时间来追求自己的学术目标？我们有好消息要告诉你！您可以通过在西班牙申请学生居留许可来实现签证正规化</p>。\n\n<p>要申请学生居留许可（estancia por estudios），请确保您在西班牙还有至少 60 天的合法居留时间。我们的律师办公室合作伙伴可以指导您完成整个流程，确保您的申请快速准确地完成。</p>\n\n<p>通过获得学生居留许可，你可以在学习期间合法地留在西班牙。不要让你的旅游身份阻碍你在西班牙实现学术梦想。</p>",
          },
          {
            subtitle: "申请学生签证时我需要提交哪些文件？",
            body: "<p>NL学院将为您提供注册信、邀请函和学习计划。这些官方文件概述了您的课程细节，并确认您已被我们学院录取为学生。</p>\n\n<p>我们的团队将随时为您解答任何问题，并为您提供实现学术梦想所需的支持。</p>",
          },
          {
            subtitle: "如果我的签证被拒怎么办？",
            body: "<p>在NL学院，我们知道签证被拒可能会让人感到紧张和沮丧。这就是为什么我们会在这里帮助您完成这个过程并探索您的选择。</p>\n\n<p>如果您的签证申请被拒，不用担心--我们会为您提供帮助。</p>n/n<p>另外，如果您决定不再重新申请签证，您可以申请退款或课程抵用券。您只需发送一封电子邮件至 admisiones@nlcollege.es，并附上您的护照复印件和由相应大使馆签发、签字并盖章的正式拒签信。请务必在规定期限内发送，以确保我们能及时处理您的申请。</p>",
          },
        ],
      },
    ],
  },
  work_with_us: {
    title: "和我们一起成长",
    section1: {
      heading: "我们<span class='text-primary'>正在招聘！</span>",
      paragraph1:
        "你在寻找新的机会吗？我们一直在寻找新的人才。如果你是教学、行政或市场营销领域的专业人士，来打个招呼！",
      btn: "我们目前的职位空缺",
    },
    section2: {
      title: "如何申请",
      steps: [
        "填写下面的表格",
        '请将您的简历发送至 <a class="text-primary" href="mailto:people@nlcollege.es">people@nlcollege.es</a>',
        "通过领英申请",
      ],
    },
    card: {
      heading: "你有兴趣进一步了解我们学校吗？",
      description: "探索我们的两个校区",
    },
    form: {
      title: "你的详细资料",
    },
  },
  our_campus: {
    title: "我们的校园",
    section1: {
      heading: '欢迎来到我们的 <span class="text-tertiary">学校</span>',
      barcelona: "巴塞罗那",
      madrid: "马德里",
      paragraph1:
        "我们为让学生有机会体验马德里和巴塞罗那的精华而感到非常自豪。我们在这些充满活力的城市中地理位置优越的校园使我们的学生能够沉浸在当地文化中，创造难忘的回忆。",
      paragraph2:
        "我们的校园位于马德里标志性的太阳门街区的中心地带，距离地铁站仅一箭之遥，可通过公共交通轻松抵达。更不用说，它靠近马约尔广场和普拉多国家博物馆等热门景点。",
      paragraph3:
        "我们的校区位于巴塞罗那迷人的圣热尔瓦西区（Sant Gervasi）的萨尔利亚（Sarrià），距离FGC Pl. Molina 站步行仅需 2 分钟，距丰塔纳地铁站步行仅需 6 分钟。附近交通便利，可轻松前往市内主要景点，包括世界著名的居尔公园（Park Güell）。",
      paragraph4:
        "在我们学校，我们相信学习西班牙语不仅仅是语言习得，还要体验西班牙的丰富文化。这就是为什么我们提供大量的额外活动和短途旅行，让我们的学生能够更深入地了解当地的庆祝活动、文化、美食等。加入我们，踏上这段难忘的语言学习和文化沉浸之旅！",
    },
    barcelona: {
      title: '巴塞罗那<br /><span class="text-tertiary">校园</span>',
      header: "巴塞罗那太好玩啦！",
      subheader:
        "在巴塞罗那学习为探索这座色彩缤纷、夜生活令人兴奋的充满活力的城市提供了难得的机会",
      paragraph1:
        "这座城市是一个文化中心，汇集了丰富的体育、历史、艺术和美食。从高迪的建筑奇迹到迷人的哥特区，巴塞罗那的每个角落都一定会让您着迷。更重要的是，随着对公司的需求不断增长，尤其是在科技领域，这座城市已成为重要的网络点。",
      paragraph2:
        "我们的校园位于该市最好的社区之一，距离地铁站仅 5 分钟步行路程。 我们现代化的 5 层建筑设有可欣赏壮丽景色的露台、13 间教室和学生休息室，营造出一个充满活力、身临其境的环境，让您体验西班牙文化。 我们的设施旨在促进学生的学习，让他们在我们这里的经历真正难忘。",
      paragraph3:
        "在我们的校园，我们认为教学质量与学生的整体体验同样重要。这就是为什么我们的课程限制为每堂课最多12名学生，以确保我们的母语和专业教师提供个性化关注的原因。我们提供一系列西班牙语和英语课程，以满足您在课堂内外的所有需求。加入我们，踏上这段激动人心的语言学习和文化沉浸之旅！",
      information: {
        title: "你需要知道什么",
        card1: {
          title: "巴塞罗那拥有一切",
          body: "运动、历史、艺术、建筑、美丽的海滩、无与伦比的天气和独特的美食",
        },
        card2: {
          title: "位置很棒",
          body: "<li>从FGC莫利纳站步行2分钟</li> <li>距丰塔纳地铁站步行5分钟路程</li>",
        },
        card3: {
          title: "现代、宽敞的设施",
          body: "在宽敞明亮的教室里享受您的课程。每班学生人数上限：12",
        },
      },
      slider: [
        {
          img: "slide_bcn_01",
          caption: "全新和现代化的设施",
        },
        {
          img: "slide_bcn_02",
          caption: "多元化社区",
        },
        {
          img: "slide_bcn_03",
          caption: "舒适的学生休息室",
        },
        {
          img: "slide_bcn_04",
          caption: "每周额外活动",
        },
        {
          img: "slide_bcn_05",
          caption: "Free Wi-fi",
        },
      ],
    },
    madrid: {
      title: '马德里<br /><span class="text-secondary">校园</span>',
      paragraph1:
        "这座城市拥有便捷的地铁交通网络和通往 154 个不同目的地的国际机场，从世界各地均可轻松抵达。从迷人的雷蒂罗公园到世界上最好的足球俱乐部之一，马德里有适合每个人的东西。",
      paragraph2:
        "在我们学校，我们致力于为您提供难忘的西班牙体验，无论这意味着提高您的语言技能还是让自己沉浸在西班牙所能提供的一切中。自2017年以来，我们一直为来自世界各地的本地教师和学生提供高质量的教学。",
      paragraph3:
        "当你在我们的NL学院马德里校区学习时，你会发现自己身处这座城市最具标志性的地标之一——太阳门广场，就在市中心。您可能需要的一切都触手可及，从便捷的公共交通到令人惊叹的景点、美味的食物以及来自世界各地的多元化社区。加入我们，踏上这段激动人心的文化探索和语言学习之旅！",
      header: "从马德里到<br />天空",
      subheader:
        "作为西班牙的首都和欧盟的第三大城市，马德里是一个繁华的活动和文化中心。",
      information: {
        title: "你需要知道什么",
        card1: {
          title: "从马德里到天空？",
          body: "你知道他们为什么说 “De Madrid al cielo” 吗？因为，继马德里之后，唯一更好的是，天空！",
        },
        card2: {
          title: "位置很棒",
          body: "学校位于马德里的中心地带。从太阳门出发，你可以去任何你想去的地方",
        },
        card3: {
          title: "5 年以上的经验",
          body: "自2017年以来，我们一直为来自世界各地的本地教师和学生提供高质量的教学",
        },
      },
      slider: [
        {
          img: "slide_mad_01",
          caption: "全新和现代化的设施",
        },
        {
          img: "slide_mad_02",
          caption: "多元化社区",
        },
        {
          img: "slide_mad_03",
          caption: "舒适的学生休息室",
        },
        {
          img: "slide_mad_04",
          caption: "每周额外活动",
        },
        {
          img: "slide_bcn_05",
          caption: "Free Wi-fi",
        },
      ],
    },
    accreditations: {
      title: "认证",
      cervantes: "由塞万提斯学院认证",
      dele_siele: "官方考试中心：",
      cambridge: "授权考试中心",
    },
    links: {
      title: "但是等等，还有更多！",
      card1: {
        title: "认识你的老师",
        body: "进一步了解我们的团队",
      },
      card2: {
        title: "哪门课程最适合你的需求？",
        body: "我们为每个人提供选择",
      },
      card3: {
        title: "谁说学习不可能很有趣？",
        body: "我们每个月都有不同的额外文化活动。看看他们！",
      },
    },
    contact_form: {
      location: "巴塞罗那格拉西亚大道的建筑",
    },
  },
  our_courses: {
    title: "我们的课程",
    section1: {
      header: '学习的时间<span class="text-tertiary">就是现在</span>',
      spanish: "西班牙语",
      english: "英语",
      dele_siele: "DELE, CCSE & SIELE 预备课程",
      paragraph1:
        "感谢您选择NL语言学院作为您的语言学习之旅。我们知道，在语言学习方面，每个学生都有独特的需求和目标。因此，我们提供一系列西班牙语和英语课程，以满足您的个人需求。",
      paragraph2:
        "我们的课程旨在满足不同水平的个人的需求，从 A1（入门）到 C2（精通）。如果您已经熟悉该语言，欢迎您加入我们现有的任何小组，这些小组每周一开课。但是，如果您刚刚开始，完全不用担心。我们有多个开课日期可供选择，您可以在<a download target='_blank' href='/files/CALENDARS_2023.pdf'>此处查看。</a>",
      paragraph3:
        "我们的语言专家团队致力于为学生提供最好的语言教育。除常规课程外，我们还提供备考课程，帮助您在语言考试中取得优异成绩。",
      paragraph4:
        "在NL学院，我们深知语言学习是一项具有挑战性的工作，因此我们在学习的每一步都提供支持。我们的目标是帮助您实现语言目标，确保您的语言学习之旅既愉快又成功。",
    },
    spanish_courses: {
      header: '<span class="text-tertiary">西班牙语</span> <br />课程',
      information: {
        header: "你好，我们开始吧!",
        paragraph1:
          "在世界上最令人印象深刻的两座城市体验西班牙语之美： 巴塞罗那和马德里。",
        paragraph2:
          "我们的成人西班牙语课程可满足各种需求和时间安排，确保您在友好、专业的环境中实现个人学习目标。在 NL 学院，我们了解每个人的学习方式都不尽相同，这也是为什么我们提供针对每个学生的课程。",
        paragraph3:
          "探索我们的一系列课程，找到最适合您个人需求的课程。按照自己的节奏学习，让我们帮助您实现语言学习目标。",
      },
      buttons: [
        {
          anchor: "#es-courses",
          title: "课程",
        },    
        {
          anchor: "#es-packages",
          title: "长期套餐",
        },
        {
          anchor: "#es-private-classes",
          title: "私人教师",
        },
        {
          anchor: "#es-online-classes",
          title: "线上课程",
        },
        {
          anchor: "#es-preparation-courses",
          title: "预备课程",
        },
      ],
      sections: [
        {
          anchor: "es-courses",
          title: "课程",
          items: [
            {
              title: "加速课程",
              visa_requirements:
                "本课程符合长期签证要求",
              check_prices: "查看我们的价目表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 20 小时",
                  days: "每天 4 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "上午 9:30 — 下午 1:30",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 2:00 — 下午 6:00",
                        days: "周一至周五*",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "上午 9:00 — 下午 1:00",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 1:30 — 下午 5:30",
                        days: "周一至周五*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请参阅我们的日历以了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A1 到 C1",
                },
                prices: {
                  title: "价格 / 月",
                  desc: ["€ 634"],
                  msg: "请记住：您选择的周数越多，获得的折扣越多！",
                },
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle: "想要快速学习西班牙语吗？",
                  body: `
                    <p>
                      我们的西班牙语速成班就是完美的解决方案！ 这备受赞誉的课程
                      专为长期学习需要满足签证要求的学生设计,但又希望在短时间内
                      提高他们的交流能力、理解能力和写作能力、掌握语法技能。
                    </p>

                    <p>
                      通过我们的西班牙语速成课程，您将看到立竿见影的效果，并在短
                      时间内提高您的交际能力。我们的专业教师将指导你进行日常口语
                      表达和书面理解练习，确保你接触到大量的语言。
                    </p>

                    <p>
                      课程分为两个部分，每个时段由不同的母语教师授课，中间有短暂的
                      休息时间。这意味着您将享受到两种独特的教学风格，并受益于NL语
                      学院教学团队中两位杰出教授的专业知识。今天就加入我们，加速你
                      的西班牙语学习之旅！
                    </p>`,
                },
              ],
            },
            {
              title: "强化课程 (10 + 10)",
              visa_requirements:
                "本课程符合长期签证要求",
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 20 小时",
                  days: "每天 4 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "下午 2:00 — 下午 4:00",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 4:00 — 下午 6:00",
                        days: "周一至周五*",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "下午 1:30 — 下午 3:30",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 4:30 — 下午 5:30 pm",
                        days: "周一至周五*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请查看我们的日历，了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A2 到 C2",
                },
                prices: {
                  title: "价格/月",
                  desc: ["€ 568"],
                  msg: "请记住：选择的周数越多，获得的折扣就越多！",
                },
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle: "通过我们的强化课程最大限度地利用您的早晨",
                  body: `
                    <p>
                      在NL语言学院，我们在巴塞罗那和马德里开设了西班牙语强化
                      课程，该课程非常适合从 A2 级开始学习的长期学生，并且符
                      合在西班牙申请学生签证的要求。
                    </p>
                    <p>
                      该课程允许学生在上午自由活动，最大限度地体验西班牙文化。课程
                      采用以学生为中心的交际方法，旨在培养学生的综合语言技能：理
                      解、表达、互动和调解。
                    </p>
                    <p>
                      在我们的下午强化课程计划中，学生每周学习 10 个小时的普通西班
                      牙语，并辅以 10 个小时的选修课：5 个小时的会话课程和 5 个小时
                      的商务西班牙语课程。这样，学生每周都有时间学习西班牙语的各个
                      方面，并能在不急于求成的情况下从一门课程到另一门课程、从一个
                      级别到另一个级别循序渐进地学习，同时提高口语表达能力，为自己
                      的职业未来投资。
                    </p>`,
                },
              ],
            },
            {
              title: "半强化课程",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 10 小时",
                  days: "每日 2 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "下午 2:00 - 下午 4:00",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 4:30 - 下午 6:30 pm",
                        days: "周一至周五*",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "下午 1:30 - 下午 3:30 pm",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 3:30 - 下午 5:30 pm",
                        days: "周一至周五*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请查看我们的日历，了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A1 到 C2",
                },
                prices: {
                  title: "价格/月",
                  desc: ["€311"],
                  msg: "请记住：选择的周数越多，获得的折扣就越多！",
                },
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle:
                    "适合您繁忙日程的高效西班牙语课程",
                  body: `
              <p>我们的半密集西班牙语课程是完美的解决方案！我们的课程专为学习
              西班牙语时间有限的学生或希望将学习西班牙语与工作或其他学习结
              合起来的学生设计，与个人授课相比，我们的小组授课生动活泼，价格实惠。</p>

              <p>我们的专业教师将指导您学习全面的课程，涵盖西班牙语的各个方
              面，从口语表达到写作和理解。今天就加入我们友好而专业的学习环
              境，迈出掌握西班牙语的第一步！</p>`,
                },
              ],
            },
            {
              title: "晚间课程",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 4 小时",
                  days: "每日 2 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "下午 6:30 - 下午 8:30",
                        days: "周一和周三*",
                      },
                      {
                        hours: "下午 6:30 - 下午 8:30",
                        days: "周二和周四*",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "下午 6:30 - 下午 8:30",
                        days: "周一和周三*",
                      },
                      {
                        hours: "下午 6:30 - 下午 8:30",
                        days: "周二和周四*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请查看我们的日历，了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A1 到 C2",
                },
                prices: {
                  title: "价格/月",
                  desc: ["€140"],
                  msg: "请记住：选择的周数越多，获得的折扣就越多！",
                },
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle:
                    "兼顾日常工作和学习的理想选择",
                  body: `
              <p>
                我们理解学生们忙碌的生活，这就是为什么我们广泛的西班牙语课程
                是为那些在一周内有全职工作、大学学习或其他责任的学生设计的。
                我们的目标是为您提供平衡日常生活和学习所需的灵活性。
              </p>

              <p>
                我们的专业教师将指导您学习全面的课程，涵盖西班牙语的各个方
                面，从口语表达到写作和理解。我们友好而专业的学习环境将帮助您
                在实现语言学习目标的过程中感受到支持和激励。
              </p>

              <p>
                今天就加入我们丰富的西班牙语课程，为你的未来投资。我们期待您
                加入我们这个充满热情的语言学习者社区！
              </p>
              `,
                },
              ],
            },
            {
              title: "会话课",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 5 小时",
                  days: "每天 1 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "下午",
                        days: "周一至周五",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "下午",
                        days: "周一至周五",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请查看我们的日历，了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A2 到 C1",
                },
                prices: {
                  title: "价格/月",
                  desc: ["€136"],
                  msg: "请记住：选择的周数越多，获得的折扣就越多！",
                },
                depending: null,
              },
              description: [
                {
                  subtitle: "让你的西班牙语更上一层楼",
                  body: `
                    <p>
                      我们的会话课程旨在提高西班牙语交际能力和表达自信心。针对 A2
                      级或以上水平的学生，重点是口语表达练习、词汇、发音和语言能力。
                    </p>
                    <p>
                      课程内容丰富多彩，充满活力，每周安排 5 个课时，周一至周五每天
                      一小时会话。课程每周涵盖五个不同的类别，使内容多样化，鼓励学
                      生积极参与。使用报纸、歌曲和最新视听材料等真实材料。
                    </p>
                    <p>
                      *类别 辩论、根据提议的主题进行会话、口头调解、对话和角色扮演
                      游戏以及口头报告。
                    </p>`,
                },
              ],
            },
            {
              title: "商务西班牙语课程",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 5 小时",
                  days: "每天 1 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "下午",
                        days: "周一至周五",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "下午",
                        days: "周一至周五",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请查看我们的日历，了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A2 到 C1",
                },
                prices: {
                  title: "价格/月",
                  desc: ["€175"],
                  msg: "请记住：选择的周数越多，获得的折扣就越多！",
                },
                depending: null,
              },
              description: [
                {
                  subtitle: "西班牙语说得像个老板",
                  body: `
                    <p>
                      NL学院的商务西班牙语课程专为那些需要在职场中游刃有余并希望为在全球范围内工
                      作做好准备的人而设计。这些课程将帮助学生提升个人简历，在众多专业人士中脱颖而出。
                    </p>
                    <p>
                      每门课程都针对 A2 或更高水平的学生，侧重于在专业和商务环境中练习西班牙语。课
                      程内容丰富实用，每周 5 课时，周一至周五每天 1 小时。
                    </p>
                    <p>
                      课程包括技术文章阅读理解、商务和专业会话、商务词汇以及医学、法律、国际关
                      系、旅游和外交等特定领域的交流等活动。此外，该课程还根据学生的情况和期望提
                      供个性化培训。
                    </p>
`,
                },
              ],
            },
          ],
        },
        {
          anchor: "es-packages",
          title: "长期套餐",
          items: [
            {
              title: "高级加速课程 25小时",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 25 小时",
                  days: "每天 4 小时+1 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "加速课程",
                        bold: true,
                      },
                      {
                        hours: "上午 9:30 - 下午 1:30",
                        days: "周一至周五",
                      },
                      {
                        hours: "会话课",
                        bold: true,
                      },
                      {
                        hours: "下午 2:00 - 下午 3:00 或",
                      },
                      {
                        hours: "下午 4:00 - 下午 5:00",
                        days: "周一至周五",
                      },
                      {
                        hours: "商务西班牙语课",
                        bold: true,
                      },
                      {
                        hours: "下午 3:00 - 下午 4:00 或",
                      },
                      {
                        hours: "下午 4:00 - 下午 5:00",
                        days: "周一至周五",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "加速课程",
                        bold: true,
                      },
                      {
                        hours: "上午 9:00 - 下午 1:00",
                        days: "周一至周五",
                      },
                      {
                        hours: "会话课",
                        bold: true,
                      },
                      {
                        hours: "下午 1:30 - 下午 2:30 或",
                      },
                      {
                        hours: "下午 3:30 - 下午 4:30",
                        days: "周一至周五",
                      },
                      {
                        hours: "商务西班牙语课",
                        bold: true,
                      },
                      {
                        hours: "下午 2:30 - 下午 3:30 或",
                      },
                      {
                        hours: "下午 4:30 - 下午 5:30",
                        days: "周一至周五",
                        with_border: true,
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请查看我们的日历，了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A2 到 C2",
                },
                prices: {
                  title: "6个月的价格",
                  desc: [
                    "加速课程 + 会话课: € 4,083",
                    "加速课程 + 商务西班牙语课: € 4,287",
                  ],
                  msg: "请记住：选择的周数越多，获得的折扣就越多！",
                },
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle: "充分利用您在西班牙的逗留时间",
                  body: `
                    <p class='h6'>2 种选择:</p>

                    <ul class="h6 disc-style disc-start disc-tertiary">
                      <li>加速课程 (20 小时) + 会话课 (5 小时)</li>
                      <li>加速课程 (20 小时) + 商务西班牙语课 (5 小时)</li>
                    </ul>


                    <p>
                      NL学院开设西班牙语速成班，旨在短时间内最大限度地提高学习效率。该课程包括每
                      周 20 个小时的加速西班牙语课程，以及 5 个小时的会话课程或商务西班牙语课程，每
                      周总计 25 个小时。
                    </p>
                    <p>
                      课程采用以学生为中心的交际方法，重点培养学生的所有语言技能，包括理解、表
                      达、互动和调解。该课程非常适合那些希望充分利用在西班牙逗留的时间，在提高口
                      语表达能力的同时逐级晋升，并为自己的职业未来投资的人。
                    </p>
                    <p>
                      对于希望获得沉浸式高效学习体验的学生来说，无论是一周还是一个月，"加速强化 
                      "课程都是一个极佳的选择。小组课程在上午进行，并有额外的沉浸式活动，以充分利
                      用在巴塞罗那或马德里的逗留时间。此外，学生还将获得学习和交流策略，以反思自
                      己的学习过程并应对西语世界的文化多样性。
                    </p>
`,
                },
              ],
            },
            {
              title: "特级加速课程 30小时",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 30 小时",
                  days: "每天 4 小时 + 1 小时 + 1 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "加速课程",
                        bold: true,
                      },
                      {
                        hours: "上午 9:30 - 下午 1:30",
                        days: "周一至周五",
                      },
                      {
                        hours: "会话课 & 商务西班牙语课",
                        bold: true,
                      },
                      {
                        hours: "下午 2:00 - 下午 3:00 或",
                      },
                      {
                        hours: "下午 4:00 - 下午 5:00 或",
                        days: "周一至周五",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "加速课程",
                        bold: true,
                      },
                      {
                        hours: "上午 9:00 - 下午 1:00",
                        days: "周一至周五",
                      },
                      {
                        hours: "会话课 & 商务西班牙语课",
                        bold: true,
                      },
                      {
                        hours: "下午 1:30 - 下午 2:30 或",
                      },
                      {
                        hours: "下午 3:30 - 下午 4:30",
                        days: "周一至周五",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请查看我们的日历，了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A2 到 C2",
                },
                prices: {
                  title: "6 个月的价格",
                  desc: ["€ 5,001"],
                  msg: "请记住：选择的周数越多，获得的折扣就越多！",
                },
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle: "最完善的西班牙语课程",
                  body: `
                    <p class='h6'>2 种选择:</p>

                    <ul class="h6 disc-style disc-start disc-tertiary">
                      <li>加速课程 (4 小时) <br> + 会话课 (1 小时) <br> + 商务西班牙语课 (1 小时)</li>
                    </ul>

                    <p>
                      在我们学校，我们根据学生的情况、需求和时间安排，帮助他们实现学习目标。我们
                      提供特级西班牙语速成课程，该课程将加速西班牙语课程（每天 4 小时）与每天 1 节
                      会话课和 1 节商务西班牙语课相结合，每周共 30 小时。这种每天 6 小时的强化课程
                      非常适合那些希望最大限度地利用时间并在西班牙享受全面学习体验的学生。
                    </p>
                    <p>
                      特级西班牙语速成课程不仅涵盖了语言的各个方面，还提高了口语表达能力，并为学
                      生的职业未来提供了宝贵的工具。该课程以交流和学生为中心，是希望全面投入西班
                      牙语学习的学生的最佳选择。NL学院致力于提供现代而有意义的西班牙语和西班牙文
                      化，确保学生积极主动地学习。
                    </p>`,
                },
              ],
            },
          ],
        },
        {
          anchor: "es-private-classes",
          title: "私人教师",
          items: [
            {
              title: "私人教师",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周最长 9.10 小时",
                  days: "每堂课 1.5 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        hours: "从 8:30 开始",
                        days: "周一至周五",
                      },
                      {
                        hours: "至 20:30 时",
                      },
                    ],
                  },
                ],
                calendar: null,
                levels: {
                  title: "级别",
                  desc: "从 A2 到 C2",
                },
                prices: null,
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle: "灵活的个性化西班牙语课程",
                  body: `
                    <p>
                      我们提供私人西班牙语课程，为学生实现学习目标提供灵活性和个性化支持。这些课
                      程完全根据每个学生的学习风格和具体目标量身定制，非常适合那些追求灵活性和适
                      应性的学生。我们独有的 "自然学习与交流 "计划允许学生通过不同的课程和级别取得
                      进步，促进个人学习目标的实现。
                    </p>
                    <p>
                      此外，我们还提供针对特定目的的私人课程，如商务西班牙语、医学西班牙语、国际
                      关系或外交。我们还为儿童和青少年开设了私人课程，通过引人入胜的活动和专门的
                      材料促进有意义的学习。这些课程可以是个人课程，也可以是夫妻、朋友或最多三人
                      的小组课程，完全根据他们的时间安排。在高素质教师的指导下，我们的学生将按照
                      自己的节奏和需求学习西班牙语。
                    </p>`,
                },
              ],
            },
          ],
        },
        {
          anchor: "es-online-classes",
          title: "在线课程",
          items: [
            {
              title: "加速课程 - 在线",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 20 小时",
                  days: "每日 4 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "上午 9:30 - 下午 1:30",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 2:00 - 下午 6:00",
                        days: "周一至周五*",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "上午 9:00 - 下午 1:00",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 1:30 - 下午 5:30",
                        days: "周一至周五*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请查看我们的日历，了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A2 到 C2",
                },
                prices: {
                  title: "价格/月",
                  desc: ["€ 634"],
                  msg: "请记住：选择的周数越多，获得的折扣就越多！",
                },
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle: "在家就能快速学习西班牙语！",
                  body: `
                    <p>
                      在线西班牙语速成课程是希望在家或在任何地方都能充分利用时间尽快学习西班牙语
                      的学生的最佳选择。通过我们独一无二的学习计划，实现你的西班牙语学习目标，并
                      与NL语言学院一起避免通勤，为减少碳排放做出贡献！
                    </p>
                    <p>
                      通过我们的在线速成课程，您将看到立竿见影的效果，并在短时间内提高您的交流技
                      能，就像我们的面授课程一样。您可以像在校园里上课一样，看到、听到、与老师和
                      同学互动和交谈。
                    </p>
                    <p>
                      在NL语言学院，我们使用 Zoom 平台，它为我们提供了所有可用的资源，让我们获得
                      最佳的学习体验：虚拟白板、共享文档、结对或小组合作，以及通过聊天和麦克风进
                      行对话。当然，你们还可以在我们的数字校园 Difusión 平台上获得在线版的课堂手册
                      和所有视听材料。
                    </p>
                    <p>
                      我们的教师将指导您进行日常口语表达或书面理解练习，确保您持续不断地接触到高
                      质量的语言。
                    </p>`,
                },
              ],
            },
            {
              title: "半加速课程 - 在线",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想了解更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周 10 小时",
                  days: "每日 2 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        title: "巴塞罗那",
                        hours: "下午 2:00 - 下午 4:00",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 4:00 - 下午 6:00",
                        days: "周一至周五*",
                        with_border: true,
                      },
                      {
                        title: "马德里",
                        hours: "下午 1:30 - 下午 3:30",
                        days: "周一至周五*",
                      },
                      {
                        hours: "下午 3:30 - 下午 5:30",
                        days: "周一至周五*",
                      },
                    ],
                  },
                ],
                calendar: {
                  text: "请查看我们的日历，了解开始日期",
                  link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
                },
                levels: {
                  title: "级别",
                  desc: "从 A1 到 C2",
                },
                prices: {
                  title: "价格/月",
                  desc: ["下午 - €311"],
                  msg: "请记住：选择的周数越多，获得的折扣就越多！",
                },
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle:
                    "适合您繁忙日程的高效西班牙语课程",
                  body: `
                    <p>
                      半加速在线西班牙语课程是时间有限的学生学习西班牙语的最佳选择，也是那些希望
                      将学习西班牙语与工作、家庭或学习相结合的学生的最佳选择，而无需花费时间通勤。
                    </p>
                    <p>
                      通过我们的在线半加速课程，您将看到立竿见影的效果，并培养您的交流技能，每天
                      只需两小时的课程，就像我们的面授课程一样。您可以像在校园里上课一样，看到、
                      听到、与老师和同学互动和交谈。
                    </p>
                    <p>
                      在NL语言学院，我们使用 Zoom 平台，它为我们提供了所有可用的资源，让我们获得
                      最佳的学习体验：虚拟白板、共享文档、结对或小组合作，以及通过聊天和麦克风进
                      行对话。当然，你们还可以在我们的 Campus Difusión 平台上获得在线版的课堂手册
                      和所有视听材料。.
                    </p>
                    <p>
                      我们的教师将指导您进行日常的口头表达或书面理解练习，确保您能够持续、高质量
                      地接触到这门语言。
                    </p>`,
                },
              ],
            },
            {
              title: "私人课程 - 在线",
              visa_requirements: null,
              check_prices: "查看我们的价格表",
              cta: "我想知道更多",
              table: {
                schedule: {
                  title: "日程表",
                  hours: "每周最长 9.10 小时",
                  days: "每堂课 1.30 小时",
                },
                schedules: [
                  {
                    items: [
                      {
                        hours: "从 8:30 开始",
                        days: "周一至周五",
                      },
                      {
                        hours: "至 20:30 时",
                      },
                    ],
                  },
                ],
                calendar: null,
                levels: {
                  title: "级别",
                  desc: "从 A2 到 C2",
                },
                prices: null,
                depending: "* 视供应情况而定",
              },
              description: [
                {
                  subtitle: "灵活的个性化西班牙语课程",
                  body: `
                    <p>
                      通过我们的在线私人课程，体验灵活、个性化的西班牙语教学。通过
                      我们的在线私人课程，学生可以在自己家中或世界任何地方，享受根
                      据自己的兴趣、目标和特定西班牙语需求量身定制的课程。
                    </p>
                    <p>
                      该课程将我们独有的学习计划调整为私人课程的形式，以完全个性化
                      和灵活的方式在更短的时间内完成课程。
                    </p>
                    <p>
                      通过我们的私人课程选项，学生将获得所需的个性化和完全量身定制
                      的支持，以尽快提高西班牙语技能。我们的西班牙语速成班完全灵
                      活，旨在适应学生的时间安排和需求。
                    </p>`,
                },
              ],
            },
          ],
        },
      ],
    },
    english_courses: {
      header: '<span class="text-secondary">英语</span> <br /> 课程',
      information: {
        header: "嘿，我们走吧!",
        paragraph1:
          "英语是世界通用语言，也是寻求职业发展和与世界各地的人建立联系的重要技能。",
        paragraph2:
          "在NL学院，我们提供由母语教师授课的充满活力的英语课程，有三种不同的形式可供选择，以满足您的时间安排和目标：半加速课程、晚间课程和私人课程。不要错过这个提高语言技能和开阔视野的机会--今天就报名吧！",
        paragraph3:
          "请注意，我们的英语课程不符合在西班牙获得学生签证的要求。",
      },
      buttons: [
        {
          anchor: "#en-extensive",
          title: "晚间课程",
        },
        {
          anchor: "#en-private-classes",
          title: "私人课程",
        },
      ],
      items: [
        {
          anchor: "en-extensive",
          title: "晚间课程",
          visa_requirements: null,
          check_prices: "查看我们的价格表",
          cta: "我想了解更多",
          table: {
            schedule: {
              title: "日程表",
              hours: "每周 4 小时",
              days: "每日 2 小时",
            },
            schedules: [
              {
                items: [
                  {
                    hours: "下午 6:30 - 晚上 8:30",
                    days: "周一和周三*",
                  },
                  {
                    hours: "",
                    days: "周二和周四*",
                  },
                ],
              },
            ],
            calendar: {
              text: "请查看我们的日历，了解开始日期",
              link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
            },
            levels: {
              title: "级别",
              desc: "从 A1 到 C1",
            },
            prices: {
              title: "价格/周",
              desc: ["€ 36"],
              msg: "请记住：选择的周数越多，获得的折扣就越多！",
            },
            depending: "* 视供应情况而定",
          },
          description: [
            {
              subtitle:
                "兼顾日常工作和学习的理想选择",
              body: `
              <p>我们深知学生的生活非常忙碌，因此我们的英语课程非常广泛，以满
              足那些在一周内有全职工作、大学学习或其他责任的学生的需求。我们
              的目标是为您提供所需的灵活性，让您在日常生活和学习之间取得平衡。</p>

              <p>我们的专业教师将指导您学习全面的课程，涵盖英语语言的各个方
              面，从口语表达到写作和理解。我们友好而专业的学习环境将帮助您
              在实现语言学习目标的过程中感受到支持和激励。</p>

              <p>今天就加入我们丰富的英语课程，为你的未来投资。我们期待您加入
              我们这个充满热情的语言学习者社区！</p>
              `,
            },
          ],
        },
        {
          anchor: "en-private-classes",
          title: "私人课程",
          visa_requirements: null,
          check_prices: "查看我们的价格表",
          cta: "我想了解更多",
          table: null,
          description: [
            {
              subtitle: "灵活的个性化英语课程",
              body: `
              <p>我们的英语课程根据您的需求和时间安排量身定制，我们的教师会根
              据您的学习风格和目标进行调整。无论您喜欢上午还是下午上课，我
              们都能满足您一周中任何一天的需求。</p>

              <p>我们的课程面向个人或最多 3 人的小组，由专门教授外国人英语的经
              验丰富的母语教师授课。您可以选择亲自到我们的学校上课，也可以
              让我们的教师到您的家中或工作场所上课。

              <p>为了更加方便，我们还通过 Zoom 提供虚拟私人课程，价格与面对面
              课程相同。</p>
              `,
            },
          ],
        },
      ],
    },
    dele_courses: {
      header:
        '<span class="text-primary">DELE, CCSE & SIELE</span> <br /> 预备课程',
      information: {
        header: "验收您所学到的知识",
        paragraph1:
          "获得 DELE（西班牙外语文凭）和 CCSE（西班牙宪法和社会文化知识）证书不仅非常重要，而且对任何希望在西班牙生活和发展个人及职业生涯的人来说都是一个改变游戏规则的机会。",
        paragraph2:
          "无论你的目标是职业发展、大学入学、获得西班牙国籍还是仅仅沉浸在西班牙生活，这些证书都是你的黄金门票。让我们来探索为什么它们对于在西班牙的工作、大学和日常生活中的成功如此重要。",
      },
      buttons: [
        {
          anchor: "#dele",
          title: "DELE (A2 & B2)",
        },
        {
          anchor: "#ccse",
          title: "CCSE",
        },
        {
          anchor: "#siele",
          title: "SIELE",
        },
      ],
      items: [
        {
          anchor: "dele",
          title: "DELE 预备课程",
          visa_requirements: null,
          check_prices: "查看我们的价格表",
          cta: "我想了解更多",
          table: {
            schedule: {
              title: "日程表 <br> (4 周)",
              hours: "每周 4 小时",
              days: "每日 2 小时",
            },
            schedules: [
              {
                title: "A2 预备课程",
                items: [
                  {
                    title: "巴塞罗那",
                    hours: "下午 2:00 - 下午 3:50",
                    days: "周一至周五*",
                    with_border: true,
                  },
                  {
                    title: "马德里",
                    hours: "下午 1:30 - 下午 3:20",
                    days: "周一至周五*",
                  },
                ],
              },

              {
                title: "B2 预备课程",
                items: [
                  {
                    title: "巴塞罗那",
                    hours: "上午 9:30 - 上午 11:20",
                    days: "周一至周五*",
                  },
                  {
                    hours: "下午 2:00 - 下午 3:50",
                    days: "周一至周五*",
                    with_border: true,
                  },
                  {
                    title: "马德里",
                    hours: "上午 9:00 - 上午 10:50",
                    days: "周一至周五*",
                  },
                  {
                    hours: "下午 1:30 - 下午 3:20",
                    days: "周一至周五*",
                  },
                ],
              },
            ],
            calendar: {
              text: "有关开始日期和 DELE 考试电话，请参阅我们的日历",
              link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
            },
            levels: {
              title: "级别",
              desc: "DELE A2 或 B2 级",
            },
            prices: {
              title: "价格/周",
              desc: ["€ 695 (4 周)"],
            },
            depending: "* 视供应情况而定",
          },
          description: [
            {
              subtitle: "在NL学院，我们为您做足准备！",
              body: `
              <p>
                如果您想参加塞万提斯学院提供的官方西班牙语考试，我们有适合您的课程。我们的 DELE
                备考课程旨在帮助您通过考试，实现自己的目标。要参加该课程，您必须已经在NL学院完
                成了 A2 或 B2 课程，或者已经具备 A2 或 B2 西班牙语水平。
              </p>
              <p>
                我们知道为 DELE 考试做好充分准备有多么重要，因此我们的课程专门为帮助您取得成功而
                设计。我们不注重一般的语法或会话技巧，而是为您提供如何应对考试的解释和指导，让
                您充满信心，做好充分准备。
              </p>
              <p>
                在整个课程中，我们将为您提供支持，解决您的疑惑，并帮助您纠正任何错误。我们还将
                为您提供一系列资源和指导，帮助您按时完成考试并提高各部分的技能。我们知道，通过
                DELE 考试需要大量的奉献和努力，但我们相信，在我们的帮助下，您一定能够实现自己的
                目标。
              </p>

              <h6 class="text-primary mt-5 mb-4">DELE A2 时程表</h6>

              <div class="row exam-table-rows">
                <div class="col-md-4">
                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 3 月 18 日</i></p>
                  <p class="fw-600 mb-0">注册</p>
                  <p class="mb-4"><i>截止到 4 月 3 日</i></p>
                  <p class="fw-600 mb-0">DELE 考试</p>
                  <p class="mb-4"><i> 5 月 18 日</i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 5 月 21 日</i></p>
                  <p class="fw-600 mb-0">注册</p>
                  <p class="mb-4"><i>截止到 5 月 15 日</i></p>
                  <p class="fw-600 mb-0">DELE 考试</p>
                  <p class="mb-4"><i> 7 月 20 日</i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 9 月 30 日</i></p>
                  <p class="fw-600 mb-0">注册</p>
                  <p class="mb-4"><i>截止到 10 月 9 日</i></p>
                  <p class="fw-600 mb-0">DELE 考试</p>
                  <p class="mb-4"><i> 11 月 22 和 23 日</i></p>
                </div>
              </div>

              <h6 class="text-primary mt-5 mb-4">DELE B2 时程表</h6>

              <div class="row exam-table-rows">
                <div class="col-md-4">
                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 4 月 22 日</i></p>
                  <p class="fw-600 mb-0">注册</p>
                  <p class="mb-4"><i>截止到 2 月 21 日</i></p>
                  <p class="fw-600 mb-0">DELE 考试</p>
                  <p class="mb-4"><i> 5 月 17 和 18 日</i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 6 月 17 日</i></p>
                  <p class="fw-600 mb-0">注册</p>
                  <p class="mb-4"><i>截止到 5 月 15 日</i></p>
                  <p class="fw-600 mb-0">DELE 考试</p>
                  <p class="mb-4"><i> 7 月 12 和 13 日</i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 10 月 28 日</i></p>
                  <p class="fw-600 mb-0">注册</p>
                  <p class="mb-4"><i>截止到 10 月 9 日</i></p>
                  <p class="fw-600 mb-0">DELE 考试</p>
                  <p class="mb-4"><i> 11 月 22 和 23 日</i></p>
                </div>
              </div>
              `,
            },
          ],
        },
        {
          anchor: "ccse",
          title: "CCSE 预备课程",
          visa_requirements: null,
          check_prices: "查看我们的价格表",
          cta: "我想了解更多",
          table: {
            schedule: {
              title: "日程表 <br> (4 周)",
              hours: "每周 5 小时",
              days: "每日 2.5 个小时",
            },
            schedules: [
              {
                items: [
                  {
                    hours: "下午 6:00 - 晚上 8:30",
                    days: "周一和周三",
                  },
                ],
              },
            ],
            calendar: {
              text: "有关开始日期和 DELE 考试电话，请参阅我们的日历",
              link: "/files/A3_CALENDAR_nlcollege_2024_both.pdf",
            },
            levels: {
              title: "级别",
              desc: "从 A2 到 C1",
            },
            prices: {
              title: "价格/周",
              desc: ["€ 250 (4 周)"],
            },
            depending: "* 视供应情况而定",
          },
          description: [
            {
              subtitle: "距离获得西班牙国籍又近了一步",
              body: `
              <p>
                NL 学院每年开设八门 CCSE 预备课程，除八月和十二月外，每月最后一个星期四在马德里
                和巴塞罗那举行考试。CCSE 是塞万提斯学院的一项考试，主要评估对宪法以及西班牙文化
                和社会现实的了解程度，是获得西班牙国籍的一项要求。
              </p>
              <p>
                该课程旨在复习 CCSE 内容，要求完成 NL 学院的 A2 课程或同等西班牙语水平。准备工作
                分为两部分： 西班牙政府、立法和公民参与，以及西班牙文化、历史和社会。
              </p>
              <p>
                该课程为期 4 周，共 20 个学时，可在考试前一周至四周内灵活报名。课程将复习教学大纲
                的主要内容，并练习考试模型，还要求学生在家中进行个人备考，以解决疑虑，并在考试
                时对考试内容记忆犹新。
              </p>


              <h6 class="text-primary mt-5 mb-4">CCSE 时程表</h6>

              <div class="row exam-table-rows">
                <div class="col-md-4">
                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 2 月 26 日</i></p>

                  <p class="fw-600 mb-0">CCSE 考试</p>
                  <p class="mb-4"><i> 3 月 21 日</i></p>

                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 6 月 3 日</i></p>

                  <p class="fw-600 mb-0">CCSE 考试</p>
                  <p class="mb-4"><i> 6 月 27 日</i></p>

                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 10 月 7 日</i></p>

                  <p class="fw-600 mb-0">CCSE 考试</p>
                  <p class="mb-4"><i> 10 月 31 日</i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 4 月 1 日</i></p>

                  <p class="fw-600 mb-0">CCSE 考试</p>
                  <p class="mb-4"><i> 4 月 25 日</i></p>

                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 7 月 1 日</i></p>

                  <p class="fw-600 mb-0">CCSE 考试</p>
                  <p class="mb-4"><i> 7 月 25 日</i></p>

                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 11 月 4 日</i></p>

                  <p class="fw-600 mb-0">CCSE 考试</p>
                  <p class="mb-4"><i> 11 月 28 日</i></p>
                </div>
                <div class="col-md-4">
                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 5 月 6 日</i></p>

                  <p class="fw-600 mb-0">CCSE 考试</p>
                  <p class="mb-4"><i> 5 月 30 日</i></p>

                  <p class="fw-600 mb-0">预备课程</p>
                  <p class="mb-4"><i> 9 月 2 日</i></p>

                  <p class="fw-600 mb-0">CCSE 考试</p>
                  <p class="mb-4"><i> 9 月 26 日</i></p>
                </div>
              </div>

              `,
            },
          ],
        },
        {
          anchor: "siele",
          title: "SIELE 准备课程 - 在线",
          visa_requirements: null,
          check_prices: "查看我们的价格表",
          cta: "我想了解更多",
          table: {
            schedule: {
              title: "日程表 <br> (4 周)",
              hours: "35 - 40 小时",
              days: "灵活：选择你想学习的天数和时间",
            },
            schedules: [],
            calendar: null,
            levels: {
              title: "级别",
              desc: "从 A2 开始",
            },
            prices: {
              title: "价格",
              desc: ["€ 199"],
            },
            depending: "* 视供应情况而定",
          },
          description: [
            {
              subtitle: "认证您的西班牙语水平",
              body: `<p>
                SIELE全球备考课程专为有意获得官方西班牙语水平证书的学生设计。在学校参加考试的学
                生也可参加该课程。
              </p>
              <p>
                SIELE（国际西班牙语评估服务）是一个针对全球学生和专业人士的西班牙语水平评估和认
                证系统，以欧洲语言共同参考框架（CEFR）为基础。NL College 在巴塞罗那和马德里设有
                校区，是参加这些在线考试的官方中心，可灵活选择考试日期和时间。
              </p>
              <p>
                SIELE 证书对于进入名牌大学、申请奖学金或提高工作机会非常有价值，并得到了塞万提斯
                学院和萨拉曼卡大学等机构的认可。该证书极大地丰富了个人简历，可与其他公认的语言
                证书（如 First 或 TOEFL）相媲美。完成课程后，学生将为参加考试做好充分准备，了解考
                试结构和内容，并运用有效策略优化考试成绩。
              </p>`,
            },
          ],
        },
      ],
    },
    footer: {
      title: "我们才刚开始！",
      card1: {
        title: "认识你的老师",
        body: "进一步了解我们的团队",
      },
      card2: {
        title: "有多少关卡？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card3: {
        title: "谁说学习不可能很有趣？",
        body: "我们每个月都有不同的额外文化活动。看看他们！",
      },
    },
    contact_form: {
      location: "巴塞罗那圣家堂鸟瞰图",
    },
  },
  our_packages: {
    title: "我们的套餐",
    cta: "我想知道更多",
    section1: {
      header: '是时候<span class="text-tertiary">打包了！</span>',
      paragraph1:
        "无论你是在西班牙开始或完成大学学位的学生，还是寻求学习和度假体验相结合的团体，还是渴望探索冬季或夏季季节性奇观的人，我们都有适合你的套餐。我们的重点是改善学生在生活各个领域的体验，无论是他们的职业道路、教育、工作还是休闲。",
    },
    group_exp: {
      header: '巴塞罗那团体<span class="text-tertiary">语言体验</span>',
      title: "西班牙学习和旅行套餐选项。和我们一起计划您的下一个假期！",
      choose: "在两者之间选择",
      buttons: [
        {
          anchor: "#package1",
          title: "套餐 1：探索与潜水",
        },
        {
          anchor: "#package2",
          title: "套餐 2：探索和沉浸其中",
        },
        {
          anchor: "#package3",
          title: "套餐 3：语言静修",
        },
      ],
      tables: [
        {
          id: "套餐 1",
          title: "套餐 1：探索与潜水",
          cost: "总费用：490 欧元/人",
          items: [
            {
              title: "西班牙语速成课程",
              description:
                "5 天 - 4 小时/天（9:00 - 13:00 或 14:00 - 18:00）<br> 包括注册、材料和书籍",
            },
            {
              title: "住宿（6 晚）",
              description: "共用宿舍含早餐",
            },
            {
              title: "活动（5 天）*",
              description:
                "<ul>\n <li>文化城市之旅</li>\n <li>海滩日</li>\n <li>当地小吃之夜</li></ul>\n <ul>\n <li>蒙特惠奇山</li>\n <li>休达德拉公园</li></ul>",
            },
            {
              title: "机场接送",
              description: "包括在公共汽车中",
            },
            {
              title: "公共旅行卡",
              description: "乘坐地铁/公共汽车 10 次旅行",
            },
            {
              description:
                "时长：7 天 6 晚。起始日期：按需提供。团体：至少 10 名学生 <br>* 活动可能会有所变化，视空房情况而定",
            },
          ],
        },
        {
          id: "套餐 2",
          title: "套餐 2：探索和沉浸其中",
          cost: "总费用：530 欧元/人",
          items: [
            {
              title: "西班牙语速成课程",
              description:
                "5 天 - 4 小时/天（14:00 - 18:00）<br> 包括注册、材料和书籍",
            },
            {
              title: "住宿（6 晚）",
              description: "共用宿舍含早餐",
            },
            {
              title: "活动（5 天）*",
              description:
                "<ul>  \n                <li>带导游游览附近景点 - 2 项惊喜活动</li>\n <li>桂尔公园</li>\n <li>西班牙海鲜饭烹饪课</li>\n </ul>\n <ul> \n < li>晚间弗拉门戈表演</li>\n <li>巴塞罗那植物园</li>\n <li>Spotify 诺坎普球场之旅和博物馆</li>\n              </ul>",
            },
            {
              title: "机场接送",
              description: "包括在公共汽车中",
            },
            {
              title: "公共旅行卡",
              description: "乘坐地铁/公共汽车 10 次旅行",
            },
            {
              description:
                "时长：7 天 6 晚。起始日期：按需提供。团体：至少 10 名学生 <br>* 活动可能会有所变化，视空房情况而定",
            },
          ],
        },
        {
          id: "套餐 3",
          title: "套餐 3：语言静修",
          cost: "总费用：600 欧元/人",
          items: [
            {
              title: "西班牙语速成课程",
              description:
                "5 天 - 4 小时/天（14:00 - 18:00）<br>包括注册、材料和书籍",
            },
            {
              title: "住宿（6 晚）",
              description: "共用宿舍含早餐",
            },
            {
              title: "活动（5 天）*",
              description:
                "<ul>\n <li>冒险港</li>\n <li>巧克力博物馆</li>\n <li>巴塞罗那设计博物馆</li></ul>\n <ul>\n <li>美食用餐体验</li>\n <li>海滩日</li>\n <li>圣家堂：带语音导游的入口</li></ul>\n",
            },
            {
              title: "机场接送",
              description: "包括在公共汽车中",
            },
            {
              title: "公共旅行卡",
              description: "乘坐地铁/公共汽车 10 次旅行",
            },
            {
              description:
                "时长：7 天 6 晚。起始日期：按需提供。团体：至少 10 名学生 <br>* 活动可能会有所变化，视空房情况而定",
            },
          ],
        },
      ],
    },
    season_exp: {
      header: "巴塞罗那夏季和冬季<span class='text-tertiary'>语言体验</span>",
      title: "夏季和冬季在西班牙学习和旅行的套餐选项",
      subtitle: "在你学习一门新语言的同时，让我们一起创造难忘的体验",
      choose: "在两者之间选择",
      buttons: [
        {
          anchor: "#summer_packages",
          title: "夏季套餐",
        },
        {
          anchor: "#winter_packages",
          title: "冬季套餐",
        },
      ],
      groups: [
        {
          id: "summer_packages",
          title: "夏季套餐",
          tables: [
            {
              title: "套餐 1：冒险心情",
              cost: "总费用：330 欧元/人",
              items: [
                {
                  title: "西班牙语速成课程",
                  description:
                    "5天 - 4小时/天（9:00 - 13:00或14:00 - 18:00）<br>包括注册、材料和书籍",
                },
                {
                  title: "活动（5 天）*",
                  description:
                    "<ul> \n <li>古埃尔公园</li>\n <li>划桨冲浪</li>\n <li>布拉瓦海岸徒步旅行</li></ul>\n <ul> \n <li>徒步之旅</li>\n <li>蒙特惠奇山</li></ul>",
                },
                {
                  title: "机场接送",
                  description: "包括在公共汽车中",
                },
                {
                  title: "公共旅行卡",
                  description: "乘坐地铁/公共汽车 10 次旅行",
                },
                {
                  description:
                    "时长：7 月、8 月或 9 月从星期一到星期五的 5 天。开始日：你可以每周一加入 <br>* 活动可能会改变，视供应情况而定",
                },
              ],
            },
            {
              title: "套餐 2：放松心情",
              cost: "总费用：370 欧元/人",
              items: [
                {
                  title: "西班牙语速成课程",
                  description:
                    "5天 - 4小时/天（9:00 - 13:00或14:00 - 18:00）<br>包括注册、材料和书籍",
                },
                {
                  title: "活动（5 天）*",
                  description:
                    "<ul> \n <li>巴塞罗那植物园</li>\n <li>参观毕加索博物馆</li>\n <li>参观拉佩德雷拉</li></ul>\n <ul> \n <li>参观维森斯之家</li>\n <li>瑜伽课</li></ul>",
                },
                {
                  title: "机场接送",
                  description: "包括在公共汽车中",
                },
                {
                  title: "公共旅行卡",
                  description: "乘坐地铁/公共汽车 10 次旅行",
                },
                {
                  description:
                    "时长：7 月、8 月或 9 月从星期一到星期五的 5 天。开始日：你可以每周一加入 <br>* 活动可能会改变，视供应情况而定",
                },
              ],
            },
            {
              title: "套餐 3：派对气氛",
              cost: "总费用：390 欧元/人",
              items: [
                {
                  title: "西班牙语速成课程",
                  description:
                    "5天 - 4小时/天（9:00 - 13:00或14:00 - 18:00）<br>包括注册、材料和书籍",
                },
                {
                  title: "活动（5 天）*",
                  description:
                    "<ul> \n <li>触控音乐卡拉OK吧</li>\n <li>夜总会</li>\n </ul><li>在酒吧里闲逛</li>\n <ul> \n <li>当地小吃之夜</li>\n <li>莫科博物馆</li></ul>",
                },
                {
                  title: "机场接送",
                  description: "包括在公共汽车中",
                },
                {
                  title: "公共旅行卡",
                  description: "乘坐地铁/公共汽车 10 次旅行",
                },
                {
                  description:
                    "时长：7 月、8 月或 9 月从星期一到星期五的 5 天。开始日：你可以每周一加入 <br>* 活动可能会改变，视供应情况而定",
                },
              ],
            },
          ],
        },
        {
          id: "winter_packages",
          title: "冬季套餐",
          tables: [
            {
              title: "套餐 1：冒险心情",
              cost: "总费用：370 欧元/人",
              items: [
                {
                  title: "西班牙语速成课程",
                  description:
                    "5天 - 4小时/天（9:00 - 13:00或14:00 - 18:00）<br>包括注册、材料和书籍",
                },
                {
                  title: "活动（5 天）*",
                  description:
                    "<ul> \n <li>古埃尔公园</li>\n <li>划桨冲浪</li>\n <li>布拉瓦海岸徒步旅行</li></ul>\n <ul> \n <li>徒步之旅</li>\n <li>蒙特惠奇山</li></ul>",
                },
                {
                  title: "机场接送",
                  description: "包括在公共汽车中",
                },
                {
                  title: "公共旅行卡",
                  description: "乘坐地铁/公共汽车 10 次旅行",
                },
                {
                  description:
                    "时长：12 月、1 月或 2 月的周一至周五为期 5 天开始日：您可以每周一加入 <br>* 视空房情况而定，活动可能会有所变化",
                },
              ],
            },
            {
              title: "套餐 2：放松心情",
              cost: "总费用：390 欧元/人",
              items: [
                {
                  title: "西班牙语速成课程",
                  description:
                    "5天 - 4小时/天（9:00 - 13:00或14:00 - 18:00）<br>包括注册、材料和书籍",
                },
                {
                  title: "活动（5 天）*",
                  description:
                    "<ul> \n <li>巴塞罗那植物园</li>\n <li>参观毕加索博物馆</li>\n <li>参观拉佩德雷拉</li></ul>\n <ul> \n <li>参观维森斯之家</li>\n <li>瑜伽课</li></ul>",
                },
                {
                  title: "机场接送",
                  description: "包括在公共汽车中",
                },
                {
                  title: "公共旅行卡",
                  description: "乘坐地铁/公共汽车 10 次旅行",
                },
                {
                  description:
                    "时长：12 月、1 月或 2 月的周一至周五为期 5 天开始日：您可以每周一加入 <br>* 视空房情况而定，活动可能会有所变化",
                },
              ],
            },
            {
              title: "套餐 3：派对气氛",
              cost: "总费用：390 欧元/人",
              items: [
                {
                  title: "西班牙语速成课程",
                  description:
                    "5天 - 4小时/天（9:00 - 13:00或14:00 - 18:00）<br>包括注册、材料和书籍",
                },
                {
                  title: "活动（5 天）*",
                  description:
                    "<ul> \n <li>触控音乐卡拉OK吧</li>\n <li>夜总会</li>\n </ul><li>在酒吧里闲逛</li>\n <ul> \n <li>当地小吃之夜</li>\n <li>莫科博物馆</li></ul>",
                },
                {
                  title: "机场接送",
                  description: "包括在公共汽车中",
                },
                {
                  title: "公共旅行卡",
                  description: "乘坐地铁/公共汽车 10 次旅行",
                },
                {
                  description:
                    "时长：12 月、1 月或 2 月的周一至周五为 5 天。开始日：你可以每周一加入 <br>* 活动可能会改变，视供应情况而定",
                },
              ],
            },
          ],
        },
      ],
    },
    footer: {
      title: "我们才刚开始！",
      card1: {
        title: "认识你的老师",
        body: "进一步了解我们的团队",
      },
      card2: {
        title: "有多少级别？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card3: {
        title: "谁说学习不可能很有趣？",
        body: "我们每个月都有不同的额外文化活动。看看他们！",
      },
    },
    contact_form: {
      location: "巴塞罗那 La Sagrada Família 鸟瞰图",
    },
  },
  pathway: {
    title: "西班牙语课程和衔接<br>课程套餐",
    section1: {
      header: "探索",
      subheader:
        "我们的预科课程将助您走向成功之路，助您顺利完成西班牙公立大学的入学考试",
      title:
        "如果你打算进入西班牙教育体系并在西班牙继续深造，那么你来对地方了！ ",
      paragraph1:
        "我们为国际学生或拥有国际学士学位的学生提供最全面的西班牙语课程+大学入学考试（Selectividad）预备课程。我们的课程可确保您为进入西班牙所选学位课程所需的所有科目做好充分的准备。保证了最高的录取分数和98％的考试成功率，您将有机会申请该国的顶尖大学。",
      paragraph2:
        "一年一度的 Selectividad 课程专为在西班牙或西班牙教育体系之外完成国际高中课程或高中学习的学生量身定制。无论您是国际学生还是拥有非西班牙教育背景的学生，我们的课程都能满足您的需求，为您在西班牙高等教育体系中取得成功做好准备。",
    },
    why: {
      header: "为什么选择<br><span class='text-tertiary'>我们的套餐</span>",
      reasons: [
        {
          title: "98% 考试成功率",
          desc: "凭借我们久经考验的记录，在考试中脱颖而出。",
        },
        {
          title: "灵活的兼职时段",
          desc: "每周与学习平衡 30 小时，假期期间全职学习。",
        },
        {
          title: "高批准签证率",
          desc: "获得为期 1 年的 D 类签证、永久居留权和工作权，确保顺利进入西班牙的学习之旅。",
        },
        {
          title: "53 所公立大学",
          desc: "以与西班牙学生相同的价格（学费在 800-2.500 欧元/学年之间），从西班牙各地的各种院校中进行选择。",
        },
        {
          title: "选择性考试",
          desc: "只需选择 6 个科目参加考试",
        },
        {
          title: "学生的国籍",
          desc: "无论国籍如何，所有学生都必须参加选择性考试。",
        },
      ],
    },
    explorer: {
      pretitle: "投资您在<br>西班牙的教育未来",
      title: "深度探索包",
      subtitle: "总投资：5.900 欧元",
      table: {
        rows: [
          {
            cols: [
              {
                title: "西班牙语速成课程",
                items: [
                  "<span class='text-underlined text-tertiary'>6 个月加速课程</span>，从 7 月开始，从 A1 到 B2 级别。",
                  "包括书籍和注册",
                ],
              },
              {
                title: "通路计划",
                items: [
                  "为期 5 个月，从 1 月开始。",
                  "课程、材料和认证流程",
                  "考试和大学预注册（不包括考试和大学费用）",
                ],
              },
            ],
          },
          {
            cols: [
              {
                class: "文本中心",
                title: "学习之旅时间表",
                desc: "七月：开始你的西班牙冒险之旅<br>一月：进入Pathway项目",
              },
            ],
          },
        ],
      },
      cta: "我想知道更多",
    },
    preparation: {
      header:
        "准备<span class='text-tertiary'>大学入学考试(Selectividad)</span>",
      row1: {
        title:
          "大学入学考试(Selectividad) <br> <span class='text-tertiary'>测验</span>",
        subtitle: "对于国际学生来说，年度选择性课程有什么优势？",
        desc: "西班牙的大学入学考试是围绕西班牙高中课程的内容进行的，这使得来自不同教育体系的学生寻求我们经验丰富的教师的指导非常有益。年复一年，我们的老师帮助数百名学生成功获得西班牙大学的录取。此外，我们的 Selectividad 预备课程提供全面的支持，确保学生能够根据自己的兴趣选择自己喜欢的大学。",
      },
      row2: {
        title: "我们如何为国际学生的年度 Selectividad 课程做好准备",
        subtitle:
          "我们在UNED开设的年度Selectividad课程为学生提供了通过大学入学考试的最佳工具。",
        desc: "从课程一开始，您就可以访问更新的课程，并有充足的时间为所有科目做准备。我们的专业团队，包括协调员和教师，将协助您制定学习程序，并保持实现目标所必需的一致性。我们的方法强调通过练习和模拟考试进行定期练习，确保全面涵盖所有材料。从第一天起就有指导性支持，您可以自信地在准备工作中取得进展。如果你的目标是在其他教育体系完成高中后获得所需的学位课程的录取，那么我们在UNED开设的年度Selectividad预备课程无疑是你的最佳选择。",
      },
    },
    journey: {
      cta: "我想知道更多",
      header: "您的<br>学术<br><span class='text-tertiary'>旅程</span>",
      sections: [
        {
          title: "西班牙语速成班和大学入学考试流程",
          items: [
            {
              body: "<b>第 1 步：</b>学生将于7月开始西班牙语课程，该课程将持续六个月，直到12月。本课程是必修的，因为必须精通西班牙语才能开始以西班牙语授课的衔接课程。",
            },
            {
              body: "<b>第 2 步：</b>一月份，在完成西班牙语课程后，学生将开始衔接课程。",
            },
            {
              body: "<b>第 3 步：</b>该途径课程预计将于五月结束",
            },
            {
              body: "<b>第四步：</b>在5月完成衔接课程后，学生将被要求参加Selectividad考试，该考试定于5月20日至本月底举行。该考试由六个部分组成：四部分处于必修的通用阶段，涵盖西班牙语和文学、外语（英语）、西班牙历史以及数学或艺术基础之间的选择。此外，还有两项考试处于可选的特定阶段，包括健康科学、技术或经济学以及人文和社会研究。",
            },
            {
              body: "<b>第 5 步：</b>Selectividad 考试结束后，需要等待大约 10 天才能获得考试结果。在此期间，我们会告知学生成绩，并根据学生的表现优先考虑大学入学。",
            },
            {
              body: "<b>第 6 步：</b>掌握了 Selectividad 成绩，然后我们会见我们的学生，帮助他们完成所有他们有兴趣就读的大学的注册。",
            },
            {
              body: "<b>步骤7：</b>7月左右，学生会收到大学的录取通知书，他们使用该通知书启动在西班牙的续签程序。",
            },
            {
              body: "<b>第8步：</b>在九月的第一周，学生开始在大学学习所选学位课程的第一年。",
            },
          ],
        },
        {
          title: "所需文件",
          items: [
            {
              body: "<b>只有有效的护照：</b>学校要处理学生签证注册信，唯一需要的文件是学生的有效护照。",
            },
          ],
        },
        {
          title: "付款和注册流程",
          items: [
            {
              body: "<p>一旦学校收到学生的护照，我们将立即向学生邮寄一份有条件的录取通知书，其中包含课程的详细信息（例如开课日期和持续时间）以及用于付款的银行详细信息。</p>\n\n <p>\n <b>学生可以选择通过以下方式付款：</b></p>\n\n <ul>\n <li>银行转账</li>\n <li>现金</li>\n <li>信用卡</li></ul>",
            },
          ],
        },
        {
          title: "签证处理",
          items: [
            {
              body: "我们强烈建议学生在<b>三</b>月份之前完成注册流程，以便有足够的时间进行签证处理，并避免任何潜在的延误。",
            },
          ],
        },
      ],
    },
    university: {
      header: "大学<br><span class='text-tertiary'>预科课程</span>",
      title: "从 A1 到 B2 <br><span class='text-tertiary'>6 个月内！</span>",
      desc1:
        "该大学预科课程（衔接课程）专为非欧盟学生设计，将帮助您实现进入您选择的西班牙大学的学习目标，并使您能够继续在西班牙学习并获得高级文凭。",
      subtitle: "你会学到什么",
      desc2:
        '<p>\n        完成西班牙语 B2 级别后，您将获得\n 一套全面的技能和知识，使您能够互动并参与\n 更高级别的对话和语言情境。.\n      </p>\n\n      <p class="text-tertiary">自由表达自己！</p>\n\n <p>\n 您将能够熟练地提供有关各种主题的详细信息，\n 能够流利地进行口头和口头表达 书面。 您还将能够\n 使用沟通策略在不同的上下文和情况下进行对话。\n </p>\n\n <p class="text-tertiary">对动词有较强的掌握能力 时态</p>\n\n <p>\n 从语法上来说，您将对动词时态有很强的掌握，包括过去时、将来表达和祈使句，以及 ser 和 estar 的用法， 时间标记、话语连接符、各种句子结构，包括条件和关系从句、间接风格和非人称结构。 您还将熟练地表达个人的喜好和厌恶。\n </p>\n\n <p>\n 总的来说，完成西班牙语 B2 级别将为您提供各种\n 必不可少的语言能力 在西班牙语大学和环境中进行有效沟通。\n      </p>',
      subtitle2: "日程安排",
      desc3:
        '<ul class="disc-style Disc-start Disc-tertiary">\n <li class=\'mb-4\'>\n <p class="text-tertiary mb-2">巴塞罗那校区< /p>\n <p>\n 上午：上午 9:30 - 下午 1:30 <br />\n 下午：下午 2:00 - 下午 6:00\n </p>\n </li> \n <li>\n <p class="text-tertiary mb-2">马德里校区</p>\n <p>\n 上午：上午 9:00 - 下午 1:00 <br /> \n 下午：13:30 pm - 5:30 pm\n </p>\n </li>\n </ul>',
    },
    footer: {
      title: "我们才刚开始！",
      card1: {
        title: "认识你的老师",
        body: "进一步了解我们的团队",
      },
      card2: {
        title: "有多少级别？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card3: {
        title: "谁说学习不可能很有趣？",
        body: "我们每个月都有不同的额外文化活动。看看他们！",
      },
    },
    contact_form: {
      location: "巴塞罗那凯旋门鸟瞰图",
    },
  },
  placement_test: {
    title: "分班考试",
    header: {
      title: '您<span class="text-tertiary">知道多少？</span>',
      paragraph1:
        "参加我们的免费西班牙语或英语水平测试，评估您的语言水平！该测试是我们对新生的初步评估的一部分，旨在确保他们从适当的水平开始并且可以轻松进步。",
      paragraph2: "不要错过今天测试知识的机会，完全免费！",
    },
    spanish_test: {
      title: "西班牙语水平测试",
      exam_cta: "立即参加西班牙语考试！",
      description:
        "欢迎参加我们的语言能力测试！此评估旨在评估您的西班牙语能力。你准备好开始了吗？请按照以下说明进行操作",
      instruction1: "问题是多选题",
      instruction2: "阅读问题并标记一个答案选项。每个问题只有一个正确答案",
      instruction3: "随着测试的进展（从 A1 到 C2），问题变得越来越难",
      instruction4: "如果问题太难了，请跳到下一个问题，完成后点击提交完成测试",
      instruction5: "非常感谢你抽出宝贵的时间，祝你在测试中好运！",
    },
    english_test: {
      description:
        "欢迎参加我们的语言能力测试！此评估旨在评估您的英语能力。你准备好开始了吗？请按照以下说明进行操作：",
      instruction1: "问题是多选题",
      instruction2: "阅读问题并标记一个答案选项。每个问题只有一个正确答案",
      instruction3: "随着测试的进展（从 A1 到 C2），问题变得越来越难",
      instruction4: "如果问题太难了，请跳到下一个问题，完成后点击提交完成测试",
      instruction5: "非常感谢你抽出宝贵的时间，祝你在测试中好运！",
      title: "英语水平测试",
      exam_cta: "立即参加英语考试！",
    },
    footer: {
      title: "让我们来帮助你",
      card1: {
        title: "有多少级别？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card2: {
        title: "哪门课程最适合你的需求？",
        body: "你是赶时间还是计划长期居留？我们为每个人提供选择",
      },
      card3: {
        title: "你对签证有任何疑问吗？",
        body: "这里有一些信息可以帮助你开始这个过程",
      },
    },
    contact_form: {
      location: "巴塞罗那贝尔港",
    },
  },
  exams_and_certifications: {
    title: "考试和证书",
    header: {
      title: '结 <br> <span class="text-tertiary">业!</span>',
      paragraph1: "完成后会发生什么？",
      paragraph2:
        "完成课程后你将获得哪些认证？你必须通过任何考试吗？以下是您需要了解的有关此重要主题的所有信息。",
    },
    exams: {
      title: "考试",
      types: ["官方 ELE DELE/SIELE", "CEFR 自我评估表"],
      paragraph1:
        "在NL语言学院，我们强烈推荐参加ELE官方的能力考试，如 DELE 或 SIELE，以获得语言能力认证。不过，我们也鼓励学生使用 CEFR 自我评估表来反思自己的进步和能力。学生与教师一起确定自己是否已准备好进入下一阶段或是否需要额外支持。评估进度和课程目标的实现情况。",
      paragraph2:
        "NL语言学院使用两种评估工具：每门课程结束时的自我评估活动和基于塞万提斯学院 DELE 模式的考试。",
    },
    certificates: {
      title: "证书",
      types: ["官方 ELE DELE/SIELE", "CEFR 自我评估表"],
      information: {
        card1: {
          title: "出勤证明",
          detail1: "你的课程时长",
          detail2: "总时长",
          detail3: "已达到等级",
        },
      },
      paragraph1:
        "在NL学院完成学业后，您将获得一份出勤证书，其中包括您的课程时间、总时长和达到的水平。但是，唯一的官方语言能力认证是塞万提斯学院的DELE（西班牙语作为外语文凭）或SIELE（国际西班牙语评估服务机构）",
      paragraph2:
        "作为DELE考试中心，NL马德里学院提供有关考试日期的信息，并提供考试准备课程。请在此处查看更多详情。",
    },
    footer: {
      title: "你可能会觉得这很有帮助",
      card1: {
        title: "有多少级别？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card2: {
        title: "哪门课程最适合你的需求？",
        body: "你是赶时间还是计划长期居留？我们为每个人提供选择",
      },
      card3: {
        title: "你对签证有任何疑问吗？",
        body: "这里有一些信息可以帮助你开始这个过程",
      },
    },
    contact_form: {
      location: "来自马德里的街道",
    },
  },
  prices: {
    title: "价格清单",
    talk_to_agent: "我想和经纪人谈谈",
    header: {
      title: '所有<span class="text-primary">选项</span>',
      paragraph1:
        "NL学院提供极具竞争力的价格，在质量、文化熏陶、卓越设施和出色的成本效益之间取得了完美的平衡。",
      paragraph2:
        "有多种课程可供选择，无论您是想在我们这里短期还是长期学习，您都可以选择最适合自己需求的课程。",
    },
    course_prices: {
      header: {
        title: "课程价格",
        paragraph1:
          "我们学校已准备好欢迎你！我们很高兴提供最具竞争力的价格，在质量、独特的设施和西班牙沉浸式体验之间取得卓越的平衡。",
        paragraph2:
          "可选择速成班（每周 20 小时）、半加速班（每周 10 小时）或强化班（每周 4 小时）。",
        paragraph3:
          "选择最适合你需求和周数的课程，准备好进入人生的下一个阶段。",
      },
      details: {
        title: "你在专门寻找一门课程吗？",
        subtitle1: "探索我们全方位的西班牙语和英语课程",
        subtitle2:
          "你想学习超过12周吗？太棒了！介绍您想在我们这里停留的周数并计算您的价格。",
      },
      prices_details: {
        desc1: {
          title: "注册费",
          subtitle: "€ 40 - 仅需付一次",
        },
        desc2: {
          title: "Siele 考试 + 备考",
          subtitle: "199 欧元",
        },
        desc3: {
          title: "教材书",
          subtitle: "35 欧元",
        },
      },
      private_classes: {
        title: "私人课程",
        paragraph1:
          "根据您的需求，老师会灵活调整您的学习风格和特定的西班牙语知识目标。你会得到你想要的时间表，无论你是在早上还是晚上，任何时间和任何一天都有空，只要告诉我们，我们会为你做的",
        paragraph2:
          "它可以是一个人，也可以是最多3人的小团体，你可以在朋友之间分摊费用并节省更多的钱。",
        paragraph3:
          "课程将亲自到学校上课，或者我们也可以选择去任何地方，无论是在家里还是在工作场所。您也可以选择虚拟私人课程，其价格与面对面的私人课程相同，但要使用视频会议平台。",
      },
      dele_classes: {
        title: "<br>DELE 和 CCSE 考试准备课程",
        paragraph1:
          "DELE由塞万提斯学院组织，是国际认可的西班牙语能力资格证书。",
        paragraph2:
          "这是最重要的考试，你将展示自己的西班牙语水平。它为你在世界各地的职业生涯打开了许多大门，也为许多西班牙大学的录取打开了大门。通过我们的 DELE 备考课程为考试做好准备。",
      },
      extra_services: {
        title: "额外服务",
      },
      cta: {
        title: "您想知道我们目前有哪些促销活动吗？",
        subtitle: "与我们的顾问交谈！",
      },
    },
    payments: {
      title: '付款<span class="text-primary">选项</span>',
      header:
        "在您做出投资知识的决定后，使用我们的简单选项轻松付款。您可以选择在线支付、银行转账或直接在学校付款。",
      online_payments: {
        title: "在线支付",
        details:
          "简单、快速、高效。通过Flywire平台，您输入必要的信息并完成付款。",
        flywire: {
          barcelona: "Flywire巴塞罗那 <br />",
          madrid: "Flywire马德里 <br />",
        },
      },
      bank_transfer: {
        title: "银行转账",
        details: "您也可以通过银行转账付款。<br />我们的账户详情是：",
      },
      at_school: {
        title: "在我们学校",
        details:
          "如果您已经在西班牙，我们还提供在学校付款的选项。您可以在课程开始前的星期五下午3点之前用现金或信用卡付款。如果您选择此选项，请提前告知我们。",
      },
    },
    footer: {
      title: "让我们来帮助你",
      card1: {
        title: "关于付款，您还有其他问题吗？",
        body: "查看我们的常见问题解答",
      },
      card2: {
        title: "有多少级别？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card3: {
        title: "你对签证有任何疑问吗？",
        body: "这里有一些信息可以帮助你开始这个过程",
      },
    },
    contact_form: {
      location: "从马德里雷蒂罗公园的水晶宫内部看到的景色",
    },
  },
  student_life: {
    title: "学生生活",
    header: {
      title: '欢迎来到您的新<span class="text-secondary">生活！</span>',
      paragraph1:
        "搬到一个新的国家可能是一个令人担心受怕的经历，但我们希望确保您在巴塞罗那或马德里的学习之旅是尽可能愉快和无压力的。",
      paragraph2:
        "作为一所学校，我们随时为您提供支持。从您决定加入我们的那一刻起，我们将为您提供搬家所需的所有信息，包括有关住房、交通和文化差异的有用提示和建议。",
      paragraph3:
        "如果您有任何问题或疑虑，我们的常见问题页面是一个很好的资源，或者您可以随时直接联系我们。我们致力于为您提供所需的支持，让您充分利用在西班牙的经历。",
    },
    tabs: [
      {
        col_class: "col-12",
        image: "student-visa",
        title: "学生签证",
        links: [
          {
            title: "所有你需要知道的",
            to: { name: "student_visa" },
          },
        ],
      },
      {
        col_class: "col-12",
        image: "extra-activities",
        title: "额外活动",
        links: [
          {
            title: "谁说学习不可能很有趣？",
            to: { name: "extra_activities" },
          },
        ],
      },
      {
        col_class: "col-lg-6",
        image: "accommodation",
        title: "住宿",
        links: [
          {
            title: "探索我们的所有选项",
            to: { name: "accommodation" },
          },
        ],
      },
      {
        col_class: "col-lg-6",
        image: "insurance",
        title: "健康保险",
        links: [
          {
            title: "探索我们的所有选项",
            to: { name: "medical_insurance" },
          },
        ],
      },
    ],
    footer: {
      title: "但是等等，还有更多！",
      card1: {
        title: "认识你的老师",
        body: "进一步了解我们的团队",
      },
      card2: {
        title: "哪门课程最适合你的需求？",
        body: "我们为每个人提供选择",
      },
      card3: {
        title: "你有任何问题吗？",
        body: "查看我们的常见问题解答，找到您可能有的任何疑问的答案",
      },
    },
    contact_form: {
      location: "巴塞罗那湾的鸟瞰图",
    },
  },
  student_visa: {
    title: "学生签证",
    header: {
      title: "让我们把它变得<span class='text-secondary'>简单</span>",
      paragraph1:
        "我们知道申请长期学生签证可能是一项艰巨的任务，特别是如果您不熟悉该流程。 这就是我们来这里提供帮助的原因。 凭借我们在申请过程中为学生提供咨询的丰富经验，以及我们与专业签证律师的合作关系，您可以相信您会得到良好的帮助。",
    },
    details: {
      reminder:
        "我们提醒您，法律在不断变化，每个国家的要求各不相同，因此在开始流程时请务必咨询专家。",
    },
    footer: {
      title: "你可能会觉得这很有帮助",
      card1: {
        title: "有多少级别？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card2: {
        title: "哪门课程最适合你的需求？",
        body: "你是赶时间还是计划长期居留？我们为每个人提供选择",
      },
      card3: {
        title: "你对签证有任何疑问吗？",
        body: "这里有一些信息可以帮助你开始这个过程",
      },
    },
    contact_form: {
      location: "格兰大道，马德里",
    },
    sections: [
      {
        title: "首先是第一件事",
        untilEnd: true,
        items: [
          {
            subtitle: "要开始，您只需注册我们的西班牙语速成课程即可。",
            body: "<p>一旦您全额支付课程费用，我们将指导您\n              完成签证申请流程的每一步。 我们的目标是让流程尽可能顺利、无忧，\n              以便您可以专注于真正重要的\n              事情 - 与我们一起学习和成长。</p>",
          },
        ],
      },
      {
        title: "一定要考虑这个问题",
        untilEnd: true,
        items: [
          {
            title: null,
            body: "\n              <p>\n 您可以您的国家向西班牙总领事馆申请签证\n 或者你可以在以游客身份进入西班牙后申请。请注意\n 从西班牙境内申请签证是有时间限制的，所以如果\n 这是你的情况，请咨询我们</p>\n            ",
          },
          {
            title: null,
            body: "\n              <p>\n 这些文件必须经过翻译和叛教</p>\n            ",
          },
          {
            title: null,
            body: "\n              <p>\n 通过选择27周或更长时间的课程，您将有权续订您的课程\n 学生签证，如果你将来想这样做</p>\n            ",
          },
          {
            title: null,
            body: "\n              <p>\n 我们与可以协助您申请签证的公司建立了合作伙伴关系\n 需要额外付费。请随时联系我们以了解更多信息。</p>\n            ",
          },
        ],
      },
      {
        title: "所需文件",
        untilEnd: false,
        items: [
          {
            body: "<p>国家签证申请表</p>",
          },
          {
            body: "<p>有效和有效的照片和护照</p>",
          },
          {
            body: "<p>NL学院寄出的入学信</p>",
          },
          {
            body: "<p>经济能力证明（目前签证每月600欧元）</p>",
          },
          {
            body: "<p>犯罪记录证明</p>",
          },
          {
            body: "<p>医疗证明</p>",
          },
          {
            body: "<p>居留许可</p>",
          },
          {
            body: "<p>支付签证费（目前为80欧元）</p>",
          },
        ],
      },
    ],
  },
  accommodation: {
    title: "住宿",
    header: {
      title: '请将我们<span class="text-secondary">当成你的第二个家！ </span>',
      paragraph1:
        "我们知道，感受到有人在支持您是多么重要--尤其是当您要搬到国外时",
      paragraph2:
        "这就是为什么我们有不错的住宿公司可供选择的原因，这些公司将使这一过程变得更容易，但需要支付额外费用。",
      paragraph3:
        "得益于我们的合作伙伴网络，您将被安置在一个安全的地方，尽可能靠近学校，从而最大限度地提高您的舒适度。",
    },
    gallery: {
      photo1: "共享公寓",
      photo2: "学生宿舍",
      photo3: "旅馆",
    },
    footer: {
      title: "你可能会觉得这很有帮助",
      card1: {
        title: "有多少级别？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card2: {
        title: "哪门课程最适合你的需求？",
        body: "你是赶时间还是计划长期居留？我们为每个人提供选择",
      },
      card3: {
        title: "你对签证有任何疑问吗？",
        body: "这里有一些信息可以帮助你开始这个过程",
      },
    },
    contact_form: {
      location: "巴塞罗那鸟瞰图",
    },
    sections: [
      {
        title: "共享公寓",
        untilEnd: true,
        items: [
          {
            title: null,
            body: '<p>\n                合租公寓是巴塞罗那学生的最佳选择之一。\n 学生将拥有一间带自己床（单人床或双人床）的私人房间。\n 他们将与其他人（2 至 5 人）合租一间公寓，并会 可以使用厨房、浴室和公共区域，例如客厅和餐厅。\n </p>\n <p>\n 不包括食物，账单通常添加到每月的账单上 \n 租金（电、煤气、水和互联网）。\n </p>\n <p>\n NL College 与 <a href="https://www.spotahome.com/" 建立了合作伙伴关系 class="text-secondary">\n Spotahome</a>，提供 25% 的注册费折扣，以及 \n <a href="https://www.resa.es/" class="text-secondary">Resa</a>，为学生入住期间每个月提供 10% 的折扣。\n              </p>',
          },
        ],
      },
      {
        title: "学生宿舍",
        untilEnd: true,
        items: [
          {
            title: null,
            body: "<p>\n                学生宿舍非常适合想要与其他本地和国际学生一起生活的学生，设施齐全，包含所有费用（电、水和互联网）。 学生可以使用自己的房间和浴室（单人或共用）。 学生宿舍配有 Wi-Fi、自修室、健身房等公共区域\n、24 小时监控和清洁服务。\n              </p>",
          },
        ],
      },
      {
        title: "旅馆",
        untilEnd: true,
        items: [
          {
            title: null,
            body: "<p>\n                旅馆是短期住宿的完美选择。 学生可以预订一周以上。\n 学生可以拥有一个私人房间，但价格更贵，也可以与 2、3、4 甚至 20 人共享。 旅舍提供开放式厨房和酒吧服务，以及床单和无线网络连接。 预订旅舍时，无需支付押金或注册费。\n              </p>",
          },
        ],
      },
    ],
  },
  medical_insurance: {
    title: "医疗保险",
    header: {
      title: '我们为您<span class="text-secondary">提供服务</span>',
      paragraph1:
        "根据西班牙政府的规定，学生在西班牙逗留期间必须有足够的医疗保险，NL学院提供一些保险选项，需要额外付费购买。",
      paragraph2:
        "如果您的学生签证是长期的，则必须有全额医疗保险，没有等待期，也没有自付额。这也适用于计划停留超过90天的学生。我们与一些最大的保险公司合作，其唯一目的是为学生提供最大的保障。",
      paragraph3: "请咨询我们以获取更多具体信息。",
    },
    footer: {
      title: "你可能会觉得这很有帮助",
      card1: {
        title: "有多少级别？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card2: {
        title: "哪门课程最适合你的需求？",
        body: "你是赶时间还是计划长期居留？我们为每个人提供选择",
      },
      card3: {
        title: "谁说学习不可能很有趣？",
        body: "我们每个月都有不同的额外文化活动。看看他们！",
      },
    },
    contact_form: {
      location: "格兰大道，马德里",
    },
  },
  extra_activities: {
    title: "额外活动",
    header: {
      title: "让我们让它<span class='text-secondary'>变得与众不同！</span>",
      paragraph1:
        "在NL学院，我们相信学习一门语言不仅仅是在课堂上学习。这就是为什么我们为学生提供广泛的免费活动和额外课程，为亲身体验西班牙文化和建立终身国际友谊提供了机会。",
      paragraph2:
        "我们的课程还包括社交活动和短途旅行，让学生完全沉浸在当地文化中，探索周边地区。由于我们的学校位于知名城市，我们为每个地点提供量身定制的独特活动，因此您可以充分利用自己的语言学习之旅。",
    },
    section1: {
      title:
        '我们正在享受<span class="text-secondary">生命中最美好的时光！</span>',
      subtitle:
        "我们的学生和我们一起经历了一次奇妙的冒险，从比利牛斯山脉的雪山到滨海托萨和瓦伦西亚阳光明媚的海滩！他们结交了来自世界各地的大量新朋友，同时在卡拉OK唱歌，在传统派对上跳舞，探索西班牙所能提供的一切。这是一次难忘的旅程，充满了欢笑、学习和不可思议的回忆",
    },
    section2: {
      title: "我们的学生在加入我们的课外活动后所说的话",
      reviews: [
        {
          comment:
            "和我们的同学和埃里克共度时光很有趣。我的记忆非常美好。非常感谢！",
          person: "Lola - 中国学生",
        },
        {
          comment: "感谢您的活动。你对我们总是很友善。",
          person: "Pedro - 巴西学生",
        },
        {
          comment:
            "我们一起将每一刻变成一次难忘的冒险。非常感谢您让我们参与的活动如此特别！",
          person: "Asal - 伊朗学生",
        },
        {
          comment:
            "我要感谢大家所做的一切，还要特别感谢协调员，她总是那么和蔼可亲！我们在一起学习、聊天、玩乐，一切都那么令人愉快，我知道接下来还会有更多的活动，这让我感到很开心。",
          person: "Akak -阿尔及利亚学生",
        },
        {
          comment:
            "我参加了学校的文化活动，我真的很喜欢。这个组织对我来说似乎非常好，我们参观的地方也很有趣。这是结识来自不同地方的其他学生的机会。协调员的陪伴也非常愉快，他们非常细心。",
          person: "Márcia - 巴西学生",
        },
        {
          comment:
            "作为员工，我们也可以参与活动。我们最近去了拉莫利纳，那简直太棒了！能够更好的认识我们学校的学生真是太好了。这个地方本身就非常漂亮。老实说，那真是有趣的一天。",
          person: "Alba - NL职员",
        },
      ],
    },
    footer: {
      title: "让我们来帮助你",
      card1: {
        title: "有多少级别？",
        body: "无论你是初学者还是高级学习者，都有适合你的课程",
      },
      card2: {
        title: "哪门课程最适合你的需求？",
        body: "你是赶时间还是计划长期居留？我们为每个人提供选择",
      },
      card3: {
        title: "你对签证有任何疑问吗？",
        body: "这里有一些信息可以帮助你开始这个过程",
      },
    },
    contact_form: {
      location: "参观塞哥维亚",
    },
  },
  contact_us: {
    title: "联系我们",
    header: {
      title: '我们希望听到<span class="text-secondary">您的来信！</span>',
      paragraph1:
        "我们知道，有时您可能需要一些额外的帮助或支持，我们随时准备使这一过程尽可能简单和顺畅。",
      paragraph2:
        "我们的顾问团队随时准备回答您的任何问题或疑虑。我们相信，提供卓越的支持与提供卓越的教育同样重要，我们努力让您在NL学院的经历尽可能积极和有意义。",
      paragraph3:
        "因此，如果您需要任何帮助或支持，请随时联系我们。我们随时为您提供帮助，期待尽快收到您的回复！",
    },
    subtitle: "联系我们，开始和我们一起规划您的教育！",
    accreditation: "NL获得塞万提斯研究所认可",
  },
  pathway_landing: {
    title: "在巴塞罗那工作和学习",
    subtitle: "通过我们的预备课程探索您的成功之路。一会儿去巴塞罗那工作",
    form: {
      title: "现在就开始吧！",
      subtitle: "千万不要错过这个好机会",
      name: "全名",
      email: "电子邮件地址 *",
      phone: "微信号码 *",
      note: "* 必填字段",
      button: "走！",
    },
    advantages: {
      title: "投资我们的套餐的好处",
      items: [
        {
          title: "98% 考试成功率",
          subtitle: "凭借我们久经考验的记录，在考试中脱颖而出。",
        },
        {
          title: "灵活的兼职时段",
          subtitle: "每周与学习平衡 30 小时，假期期间全职学习。",
        },
        {
          title: "高批准签证率",
          subtitle:
            "获得为期 1 年的 D 类签证、永久居留权和工作权，确保顺利进入西班牙的学习之旅。",
        },
        {
          title: "53 所公立大学",
          subtitle:
            "以与西班牙学生相同的价格（学费在 800-2.500 欧元/学年之间），从西班牙各地的各种院校中进行选择。",
        },
        {
          title: "选择性考试",
          subtitle: "只需选择 6 个科目参加考试",
        },
        {
          title: "学生的国籍",
          subtitle: "无论国籍如何，所有学生都必须参加选择性考试。",
        },
      ],
    },
    invest: {
      title: "投资您在西班牙的教育未来",
      subtitle: "在NL学院学习西班牙语，进入你梦寐以求的西班牙大学。",
      button: "了解更多",
    },
  },
  summer_packages: {
    title: "准备在荷兰学院度过西班牙最美好的夏天",
    from: "从",
    price: "330",
    currency: "€",
    subtitle: "在六月、七月和八月选择我们的暑期语言体验套餐之一 ",
    form: {
      title: "现在就开始吧！",
      subtitle: "千万不要错过这个好机会",
      name: "全名",
      email: "电子邮件地址 *",
      phone: "微信号码 *",
      note: "* 必填字段",
      button: "走！",
    },
    packages: {
      title: "通过我们的夏季套餐，您将能够将两全其美结合起来",
      subtitle:
        "无论您是寻求学习和度假体验的融合，还是渴望在夏季探索西班牙奇观的人，我们都有适合您的套餐。 ",
      foot: "将学习西班牙语与欧洲夏季的精华相结合",
    },
    get_ready: {
      title: "为人生中最美好的夏天做好准备！",
      subtitle: "在NL学院学习西班牙语，同时体验巴塞罗那和马德里的美景",
      button: "了解更多",
    },
  },
  thanks: {
    title: "谢谢！",
    subtitle: "你离开始永久学习西班牙语又近了一步！",
    text: "我们的代理商将很快与您联系。",
  },
  blog: {
    title: "博客",
    header: {
      title: "欢迎来到我们的<span class='text-primary'>博客</span>",
      subtitle:
        "这个空间将成为你在西班牙学习所需的一切的指南。从文化适应技巧到最佳探索地点的建议，我们在这里让您的学习之旅成为一次难忘的经历。",
      desc: "通过我们的眼睛探索西班牙和语言学习世界！在这篇博客中，您将深入探讨各种话题，包括在马德里和巴塞罗那的生活、学习西班牙语和英语的小贴士、开始移居西班牙之旅的指导，以及不容忽视的有关我们学校和校园的最新动态和重要信息。",
      topics_title: "你在寻找一个特定的话题吗？查看我们的博客类别：",
    },
    contact_form: {
      location: "我们的学生去瓦伦西亚旅行",
    },
  },
  price_modal: {
    title: "Download",
    subtitle: "our price list and catalogue",
    pricelist: "Price List",
    brochure: "Brochure",
    errors: {
      terms: "Please accept terms and conditions",
      full_name: "Your name is required",
      email: "Your email is required",
      country: "Your coutnry is required",
      nationality: "Your nationality is required",
    },
  },
};
