import langInstance from "./locale/index.js";

export default {
  install(app) {
    app.config.globalProperties.$lang = (key, fromLangRoot = false) => {
      const $route = app.config.globalProperties.$route;
      const lang = window.localStorage.getItem("lang");

      return key.split(".").reduce(
        (o, i) => {
          if (o) return o[i];
        },
        fromLangRoot ? langInstance[lang] : langInstance[lang][$route.name]
      );
    };
  },
};
